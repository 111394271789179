.punchcard-title {
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.punch-entry {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.punchcard-user-info {
  width: 100%;
  color: #fff;
  font-size: 1.1rem;
}

.punchcard-user {
  text-align: center;
}

.punchcard-employeeid {
  text-align: center;
}

.punch-entry-total {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
