.queryBuilder {
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
}

.fields {
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.query {
  margin-top: 30px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.column-instructions {
  font-size: 1.3rem;
  font-weight: bold;
}

.groupby {
  margin-top: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
}

.orderby {
  margin-top: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
}

.drag-drop-zone {
  padding: 2rem;
  text-align: "center";
  background: #07f;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px #c0c0c0;
}

.drag-drop-zone p {
  color: #fff;
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}

.dropped-files li {
  color: #07f;
  padding: 3px;
  text-align: left;
  font-weight: bold;
}

.column {
  padding: 3px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #06a;
  color: #fff;
}

.form-control::placeholder {
  color: #fff !important;
}
