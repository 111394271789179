.us-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 1;
}

.us-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.us-content {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  max-width: 80%;
  top: 75;
}

.us-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #1f1e2e;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.us-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.us-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10px;
}

.us-close-button {
  color: #000;
  opacity: 0.8;
  cursor: pointer;
  padding-bottom: 3px;
}

.us-body {
  padding: 10px;
  background-color: #272a3d;
  color: #fff;
}

.us-title-bar{
  margin-bottom: 10px;
}

.us-descrip{
  font-size: 1.2rem;
  font-weight: bold;
}

.us-report-contents{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top:15px;
}

.rc-table{
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.us-table-header{
  border-bottom:2px solid black;
}

.us-table-header th{
  font-weight: bold;
  font-size:1.1rem;
}

.tr-red{
  color: red;
  text-align: right;
}

.tr-green{
  color: limegreen;
  text-align:right;
}

.tr-red-center{
  color: red;
  text-align: center;
}

.tr-green-center{
  color: limegreen;
  text-align:center;
}

