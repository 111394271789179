.ti-title {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}

.ti-title-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ti-card-title {
  display: flex;
}

.ti-f01 {
  text-align: left;
  margin-left: 20px;
}

.ti-f02 {
  text-align: right;
  margin-right: 20px;
}

.ti-item {
  width: 100%;
  cursor: pointer;
}

.ti-item-upc {
  font-size: 1.1rem;
  margin-left: 20px;
}

.ti-item-descriptor {
  font-size: 1.1rem;
  text-align: right;
  margin-right: 20px;
}

.ti-f65 {
  text-align: right;
}

.ti-f64 {
  text-align: right;
}

.ti-day-of-week {
  text-align: right;
  justify-content: end;
  width: 100%;
}

.ti-tlz-selector-spacer {
  margin-left: 10px;
  margin-bottom: 10px;
}

@media all and (max-width: 1350px) {
  .ti-f01 {
    text-align: left;
    font-size: 0.9rem;
    margin-left: 0px;
  }

  .ti-f02 {
    text-align: right;
    font-size: 0.9em;
    margin-right: 0px;
  }

  ti-f64 {
    font-size: 0.8rem;
  }

  ti-f65 {
    text-align: right;
    font-size: 0.8rem;
  }
}
