.mobiletot-store {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.mobiletot-cashier {
  text-align: center;
  font-weight: bold;
}

.mobiletot-date {
  text-align: center;
  font-weight: bold;
}

.mobiltot-totalizer-item {
  border: 1px solid #4785c2;
  border-radius: 10px;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
}

.mobiltot-right {
  text-align: right;
}
