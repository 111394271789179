.modal-pricemod-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-pricemod-wrapper {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-pricemod {
  z-index: 100;
  background: white;
  margin: 1.75rem auto;
  border-radius: 10px;
}

.modal-pricemodheader {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #4785c2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
  color: #fff;
}

.modal-pricemod-confirm-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.modal-pricemod-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10px;
}

.modal-pricemod-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.modal-pricemod-body {
  padding: 10px;
  border: 1px solid #fff;
  background-color: #272a3d;
  height: 100%;
  overflow-y: auto;
}

.modal-pricemod-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sms-price-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: auto;
  text-align: center;
}

.green-background {
  background-color: limegreen;
  color: black;
  text-align: center;
  width: 90%;
  margin: auto;
  border: 1px solid #fff;
}

.yellow-background {
  background-color: yellow;
  color: black;
  text-align: center;
  width: 90%;
  margin: auto;
  border: 1px solid #fff;
}

.sms-item-label {
  font-weight: bold;
  margin-right: 5px;
  text-align: right;
}

.sms-price-row {
  display: flex;
  flex-wrap: wrap;
}

.sms-pricemod-header-text {
  font-weight: bold;
  text-align: center;
}

.sms-header-box {
  border: 1px solid #fff;
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
}

.sms-price-box {
  border: 1px solid #fff;
  padding: 3px;
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.sms-item-box {
  border: 1px solid #fff;
  padding: 3px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 23px;
}

.sms-price-column-header {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.sms-triple-column {
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
}

.sms-dual-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sms-row-spacer {
  margin-bottom: 10px;
}

.sms-time-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sms-triple-column-even {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.sms-item-info {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  margin-bottom: 10px;
}

.sms-item-upc {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.sms-item-description {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}
