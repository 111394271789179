.clock-wrapper {
  /* background-color: #353a40;
  color: #fff; */
  height: 100%;
  width: 100%;
  overflowY: auto;
}

.logo-offset {
  margin-right: 70px;
}

.d-clock-title {
  color: #fff;
}

.menu-item {
  text-decoration: none;
  color: white;
  margin-right: 10px;
  margin-top: 5px;
}

.menu-item:hover {
  color: #f15b2a;
}

.d-clock-desktop-title {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}
