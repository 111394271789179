.hourly-m-header {
  text-align: center;
}

.hourly-m-header-date {
  font-weight: bold;
}

.hourly-m-header-store {
  font-weight: bold;
}

.hour-row {
  background-color: transparent !important;
}

.hour-row-active {
  background-color: rgba(182, 88, 174, 0.7) !important;
}
