.hours-table {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px;
}

thead {
  border-bottom: 3px solid #fff;
}

.hours-table-total {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
