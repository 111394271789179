.open-punches {
  padding: 10px;
}

.d-open-punch {
  padding: 5px;
  border: 1px solid black;
  background-color: #eee;
  border-radius: 10px;
  margin-bottom: 10px;
}

.op-btn {
  margin-left: 10px;
}

.op-storename {
  font-size: 1.3rem;
  font-weight: bold;
}

.anim-container {
  -webkit-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.29),
    inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.29),
    inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29);
  box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.29),
    inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29);
}
