.clock-body {
  height: 100%;
  width: 100%;
}

.mobile-clock-header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
}

.mobile-clock-header-button {
  position: absolute;
  top: 5px;
  left: 10px;
  color: white;
  background-color: "transparent";
  font-size: 1.6rem;
}

.clock-header-text {
  position: absolute;
  top: 8px;
  left: 55px;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
}

.clock-mobile-sidebar {
  width: 200px;
  padding: 10px;
  opacity: 0.8;
}

.clock-mobile-sidebar-header {
  color: "white";
  font-weight: bold;
  font-size: 1.4rem;
  border-bottom: 3px solid white;
}

.cms-title {
  height: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 2px solid white;
  margin-bottom: 10px;
}

.cms-item {
  line-height: 16px;
  height: 35px;
}

.cms-item-icon {
  margin-left: 10px;
  margin-right: 10px;
}

.cms-item-active {
  height: 5px;
  width: 150px;
  margin-top: 5px;
  background-color: #0000ff;
}

.mobile-clock-home-screen {
  position: fixed;
  top: 50;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  overflow: auto;
  padding-bottom: 10px;
  /* grid-template-columns: 1fr 1fr; */
  background: #1f1e2e;
  /* background: rgb(44, 72, 194); */
  border: 5px solid transparent;
  /* background: linear-gradient(
    90deg,
    rgba(44, 72, 194, 1) 0%,
    rgba(10, 10, 87, 1) 45%,
    rgba(44, 72, 194, 1) 100%
  ); */
  /* background: rgb(247, 40, 51);
  background: linear-gradient(
    90deg,
    rgba(247, 40, 51, 1) 0%,
    rgba(94, 54, 77, 1) 24%,
    rgba(53, 58, 64, 1) 51%,
    rgba(86, 55, 74, 1) 75%,
    rgba(247, 40, 51, 1) 100%
  ); */
}

.mobile-clock-home-left {
  border-right: 5px solid transparent;
  display: grid;
  margin-left: 5px;
  margin-right: 5px;
}

.mobile-clock-home-right {
  display: grid;
  margin-right: 5px;
}

.mc-d-div {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
}

.mc-d-text-l {
  font-size: 8vw;
  text-align: center;
  margin-top: 20px;
}

.mc-d-text-r {
  font-size: 8vw;
  text-align: center;
  margin-top: 15px;
}

.mc-d-icon-l {
  font-size: 12vw;
  text-align: center;
  margin-top: 20px;
}

.mc-d-icon-r {
  font-size: 8vw;
  text-align: center;
  margin-top: 10px;
}

.mobile-disabled {
  background-color: #aaa;
}

.mobile-clock-timecard {
  position: fixed;
  top: 50;
  left: 0;
  bottom: 0;
  width: 100%;
}

.clock-mobile-desktop-lowerDiv {
  background-color: #fff;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  position: relative;
}

.clock-mobile-main-date {
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.clock-mobile-lower-circle {
  background-color: RGB(7, 188, 13);
  position: absolute;
  top: -30px;
  right: 50px;
  border-radius: 50%;
  padding: 10px;
}

.clock-mobile-lower-icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
}

.clock-mobile-main-buttons {
  position: absolute;
  top: 40;
  left: 20;
}

.clock-mobile-main-button {
  display: grid;
  grid-template-columns: 70px 1fr;
}

.clock-mobile-main-button-icon {
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
}

.clock-mobile-main-button-text {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
}
