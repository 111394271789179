.d-t-d-body {
  padding: 20px;
}

.d-trans-hdr-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 20px;
}
