.center {
  margin: 0;
  padding: 0;
}

input.switcher[type="checkbox"] {
  margin: 10px;
  position: relative;
  width: 120px;
  height: 40px;
  -webkit-appearance: none;
  background: linear-gradient(0deg, #333, #000);
  outline: none;
  border-radius: 20px;
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
  cursor: pointer;
}

input.switcher:checked[type="checkbox"]:nth-of-type(1) {
  background: linear-gradient(0deg, #e67e22, #f39c12);
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
}

input.switcher[type="checkbox"]:nth-of-type(1) {
  background: linear-gradient(0deg, #70a1ff, #1e90ff);
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
}

input.switcher[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 40px;
  background: linear-gradient(0deg, #000, #6b6b6b);
  border-radius: 20px;
  box-shadow: 0 0 0 1px #232323;
  transform: scale(0.98, 0.96);
  transition: 0.5s;
}

input.switcher:checked[type="checkbox"]:before {
  left: 40px;
}

input.switcher[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  left: 70px;
  width: 4px;
  height: 4px;
  background: linear-gradient(0deg, #6b6b6b, #000);
  border-radius: 50%;
  transition: 0.5s;
}

input.switcher:checked[type="checkbox"]:after {
  left: 110px;
}

.searchFilter {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.help-btn {
  height: 35px;
  margin-top: 10px;
  margin-left: 5px;
}
