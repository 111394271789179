#app {
  overflow: hidden;
}

.appWrapper {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.logo {
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  background-color: rgba(31, 30, 46, 1);
  color: #fff;
}

.modal-records-scroll > li {
  height: 40px;
  line-height: 40px;
  font-size: 1.375em;
  cursor: pointer;
  color: #000;
}

.datepicker-scroll > li {
  height: 40px;
  line-height: 40px;
  font-size: 1.375em;
  cursor: pointer;
  color: #000;
}

.MuiPickersSlideTransition-transitionContainer > * {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: #6266ef;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: #6266ef;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: #6266ef;
}
