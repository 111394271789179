.psas-totals-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.psas-totals-widget {
  width: 80%;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    7px 9px 23px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 7px 9px 23px 5px rgba(0, 0, 0, 0);
  padding-top: 20px;
  padding-bottom: 20px;
}

.psas-totals-widget-description {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  color: orange;
}

.psas-totals-widget-value {
  color: white;
  font-size: 1.2rem;
}
