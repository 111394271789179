.d-schedule {
  overflow: hidden;
  height: 100%;
}

.schedule-main-table {
  display: flex;
  justify-content: center;
}

.schedule-wrapper {
  position: absolute;
  height: 100%;
  width: 90%;
  overflow: hidden;
  padding-bottom: 10px;
}

.dow-header {
  display: flex;
  border: 2px solid #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 60px;
}

/* .schedule-main-table {
    overflow: hidden !important;
  } */

/* .schedule-header-wrapper {
    padding-bottom: 20px;
  } */

.schedule-header-bar {
  background-color: #62abf8;
  padding-bottom: 20px;
  width: 70%;
  margin: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.29),
    inset -5px -5px 10px 5px rgba(0, 0, 0, 0.29);
}

.schedule-header-text {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.day-f-c {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.day-f-c-t {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.footer-total {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
}

.schedule-body {
  position: absolute;
  top: 60;
  bottom: 60;
  left: 0;
  right: -17px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.schedule-employee {
  display: flex;
  align-items: center;
  border-right: 1px solid #fff;
  width: 20%;
  text-align: left;
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  max-width: 20%;
  min-width: 20%;
}

.sudo-cell {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  width: 10%;
  padding: 5px;
  min-width: 10%;
}

.empty-schedule {
  width: 100%;
  text-align: center;
}

.employee {
  display: flex;
  border-bottom: 1px solid #fff;
}

.employee-name {
  display: block;
  align-items: center;
  width: 20%;
  max-width: 20%;
  min-width: 20%;
  text-align: left;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px;
}

.employee-total {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.scheduler-footer {
  position: absolute;
  top: 600;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  border: 2px solid #fff;
  height: 60px;
}

.schedule-start-text {
  margin-top: 20px;
  margin-right: 10px;
}

.shift-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.shift-button-edit {
  text-align: right;
}

.shift-id {
  text-align: center;
}
