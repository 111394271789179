.desktoptot-body {
  margin-top: 20px;
}

.desktoptot-controls {
  margin: 0 auto;
}

.desktop-tot-goback {
  background-color: blue;
  color: white;
  padding: 10px;
  width: 300px;
  border-bottom-right-radius: 10px;
  font-size: 1.4rem;
  cursor: pointer;
}

.desktoptot-store {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.desktoptot-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.desktoptot-cashier {
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
}

.desktoptot-date {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.desktoptot-totalizer-item {
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.desktoptot-totalizer-item:hover {
  background-color: #eee;
}

.desktoptot-inner-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
}

.desktoptot-totalizer {
  font-size: 1.2rem;
  font-weight: bold;
}

.totalizer-date {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}

.tot-score {
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
}

.tot-median {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.tot-widgets {
  justify-content: center;
}
