.clock-body {
  height: 100%;
  width: 100%;
}

.clock-rows {
  display: grid;
  grid-template-rows: 200px 1fr;
  height: 100%;
}

.clock-header {
  height: 100%;
  width: 100%;
  text-align: center;
}

.clock-current-date-time {
  width: 80%;
  margin: auto;
}

.clock-cdt-table {
  margin-top: 20px;
  width: 100%;
  color: #fff;
  display: grid;
  grid-template-columns: 20% 1fr 20%;
}

.clock-cdt-date {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.clock-cdt-spacer {
  width: 50%;
}

.clock-cdt-time {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.clock-main {
  /* display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-template-areas: "clock-left clock-middle clock-right"; */
  height: 100%;
  width: 100%;
}

.clock-left {
  grid-area: clock-left;
  height: 100%;
}

.clock-middle {
  grid-area: clock-middle;
}

.clock-right {
  grid-area: clock-right;
  height: 100%;
}

.d-username {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.d-clockin-name {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.d-clockin-email {
  text-align: center;
}

.d-employeeid {
  text-align: center;
}

.clock-error-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 1.4rem;
  font-weight: bold;
}
