.modal-kpi-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  transition: all ease 1s;
}

.modal-kpi-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-kpi {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  max-width: 800px;
}

.modal-kpi-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #1f1e2e;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.modal-kpi-confirm-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.modal-kpi-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10;
}

.modal-kpi-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.modal-kpi-body {
  padding: 10px;
  background-color: #272a3d;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.modal-kpi-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.kpi-legend {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3px;
}

.kpi-store-container {
  padding: 10px;
  border: 1px solid #fff;
  margin: 5px;
}

.kpi-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 1rem;
  margin-bottom: 5px;
  margin-right: 5px;
}

.kpi-description {
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 5px;
}

.kpi-amount {
  padding: 3px;
  border-radius: 5px;
  padding-left: 10px;
}

.kpi-qty {
  padding: 3px;
  border-radius: 5px;
  padding-left: 10px;
}

.kpi-green {
  background-color: limeGreen;
  color: #000;
}

.kpi-red {
  background-color: red;
  color: #fff;
}
