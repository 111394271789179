.main-time-header {
  text-align: center;
  width: 100%;
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-time-logo {
  margin-right: 10px;
}

.far fa-clock {
  font-size: 1.6rem;
}

.time-lower {
  display: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.time-lower-left {
  overflow: auto;
}

.time-lower-right {
  overflow: auto;
}

.tl-l-header {
  background-color: rgb(87, 166, 251);
  color: #000;
}

.tl-l-row {
  cursor: pointer;
}

.tl-l-row-selected {
  cursor: pointer;
  background-color: rgb(170, 201, 169) !important;
}
