.floating-sidebar-header {
  height: 30px;
  font-size: 2.5rem;
  font-weight: bold;
  position: fixed;
  top: 105px;
  left: 35px;
  color: #4783c1;
  cursor: pointer;
  z-index: 9999999;
}

.floating-sidebar-icon {
  width: 100%;
  text-align: center;
  color: #4783c1;
}

.floating-sidebar {
  position: fixed;
  top: 170px;
  left: 10px;
  background-color: #4783c1;
  color: #fff;
  opacity: 0.7;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 7px 5px rgba(0, 0, 0, 0.29),
    inset -5px -5px 5px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 5px 7px 5px rgba(0, 0, 0, 0.29),
    inset -5px -5px 5px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 5px 7px 5px rgba(0, 0, 0, 0.29),
    inset -5px -5px 5px rgba(0, 0, 0, 0.29);
}

.floating-sidebar-mini {
  animation: 400ms slideMenuIn forwards;
  animation-timing-function: ease-in;
}

.floating-sidebar-full {
  width: 250px;
  animation: 400ms slideMenuOut;
  animation-timing-function: ease-in-out;
}

.floating-sidebar::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 35px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #4783c1 transparent;
}

.floating-sidebar-line {
  width: 100%;
  height: 3px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.7;
}

.fs-hl {
  height: 2px;
  color: #fff;
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
}

.fs-mi {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.fs-full {
  width: 100%;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}

.floating-sidebar-line::before {
  content: " ";
}

.fs-mn-icon {
  font-size: 1.6rem;
  margin-left: 5px;
}

.fs-mn-text {
  margin-left: 10px;
}

.menu-item-mini {
  font-size: 1.8rem;
  text-align: center;
  width: 100%;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
  color: #fff;
}

.menu-item-full {
  text-decoration: none;
  color: #fff;
}

@keyframes slideMenuOut {
  from {
    width: 100px;
  }
  to {
    width: 250px;
  }
}

@keyframes slideMenuIn {
  from {
    width: 250px;
  }
  to {
    width: 100px;
  }
}
