.cfo-cashier {
  border: 1px solid #4785c2;
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px;
}

.cfo-contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cfo-title {
  text-align: center;
}

.cfo-storename {
  font-weight: bold;
  font-size: 1.1rem;
}

.cfo-right {
  text-align: right;
}

.cfo-cashier-name {
  font-weight: bold;
}
