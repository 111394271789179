.selectedGroup {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 5px;
}

.selectedDate {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 5px;
}

.cashier-instructions {
  text-align: center;
  border-bottom: 1px solid black;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
