.countdown-timer-wrapper {
  margin-top: 30px;
  position: absolute;
  width: 300px;
  height: 300px;
}

.countdown-timer-circle circle {
  stroke-dasharray: 300px;
  stroke-dashoffset: 300px;
  stroke-linecap: butt;
  stroke-width: 4px;
  stroke: #fd4f57;
  fill: none;
}

.countdown-timer-circle svg {
  width: 200px;
  height: 200px;
  transform: rotateZ(-90deg);
}

.time-remaining {
  position: absolute;
  top: 70px;
  left: 8060px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.user-info {
  color: #fff;
}

@keyframes countdown-animation {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 300px;
  }
}

.clock-manager-mode {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}
