.clock-display {
  border: 4px solid purple;
  border-radius: 10px;
  height: 75px;
  text-align: center;
}

.d-main-display-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
