.freshop-card-title {
  padding: 5px;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
}

.freshop-card-icon {
  width: 100%;
  text-align: center;
  font-size: 4rem;
}

.freshop-card-count {
  width: 100%;
  text-align: center;
  font-size: 4rem;
}

.freshop-card-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
