.d-trans-wrapper {
  width: 1100px;
  margin: auto;
}

.d-trans-contents {
  text-align: center;
}

.d-trans-store {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.d-trans-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.d-trans-cashier {
  font-size: 1.3rem;
  font-weight: bold;
}

.d-trans-date {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.d-trans-totalizer {
  font-size: 1.1rem;
  font-weight: bold;
}

.d-trans-body {
  padding: 10px;
}

.d-trans-row {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.d-trans-h3 {
  font-size: 2rem;
  font-weight: bold;
}

.d-trans-trans {
  border: 1px solid #fff;
  background-color: #1f1e2e;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}

.d-trans-trans:hover {
  border: 1px solid #4785c2;
  background-color: #191824;
}

.d-trans-trans-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.d-trans-tlz-name {
  font-size: 1.3rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.d-trans-one,
.d-trans-three {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.d-trans-trans-total {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.d-trans-itemcount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
}
