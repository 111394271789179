.clock-in-function-buttons {
  width: 100%;
  padding-top: 40px;
}

.clock-btn {
  width: 90%;
  height: 100px;
  margin: 0 auto;
  border: 1px solid purple;
  border-radius: 10px;
  padding: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
