.psas-box-wrapper {
  border: 1px solid #ffff;
  border-radius: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    7px 9px 23px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 7px 9px 23px 5px rgba(0, 0, 0, 0);
  display: grid;
  grid-template-rows: 1fr 50px;
}

.psas-cc-title {
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #fff;
}

.psas-box-descriptor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.psas-box-descriptor,
h2 {
  padding: 10px;
}

.psas-box-month {
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
}
