.cc-stores {
  width: 100%;
}

.cc-store {
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.cc-d-store:hover {
  border: rgba(71, 131, 193, 0.9);
  box-shadow: 4px 4px 10px 2px rgba(181, 87, 173, 0.2),
    inset -4px -4px 6px 2px rgba(181, 87, 173, 0.2);
}

.cc-d-store {
  border: 1px solid rgba(181, 87, 173, 0.8);
  border-radius: 10px;
  height: 250px;
  cursor: pointer;
  position: relative;
  box-shadow: 4px 4px 10px 2px rgba(181, 87, 173, 0.1),
    inset -4px -4px 6px 2px rgba(181, 87, 173, 0.1);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cc-d-name {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.cc-d-score {
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.cc-d-score-m-label {
  font-size: 1.2rem;
}

.cc-d-m-score {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  margin-left: 20px;
}

.cc-d-header-m {
  font-size: 6rem;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.cc-d-footer {
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  width: 100%;
}
