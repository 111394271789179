.sortable-column {
  display: grid;
  grid-template-columns: 75% 1fr 1fr;
}

.sortable-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sortable-icon {
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}

.filterable-icon {
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}
