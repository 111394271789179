.s-tools-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 1.3rem;
}

.s-tools-dropdown-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #3f3d5e;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 1.2rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition-property: opacity, visibility;
  transition-duration: 1s, 1s;
  transition-delay: 0s, 0.4s;
}

.s-tools-dropdown:hover .s-tools-dropdown-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s;
}

.s-tools-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.s-tools-item:hover {
  background-color: #726ea7;
}
