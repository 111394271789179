.d-report-spacer {
  border-top: 1px solid black;
  margin-top: 20px;
}

.d-empty {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
}

.d-reportsList {
  margin-top: 20px;
  text-align: center;
}

.d-report {
  display: inline-block;
  font-size: 1.4rem;
  cursor: pointer;
  padding: 10px;
  width: 400px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  opacity: 0.6;
  margin-left: 20px;
}

.d-report:hover {
  background-color: rgba(203, 45, 214, 0.7);
  color: #fff;
  opacity: 1;
}

.d-report-info {
  text-align: center;
}

.d-report-storename {
  font-size: 1.3rem;
  font-weight: bold;
}
