.dfo-back {
  background-color: blue;
  color: white;
  padding: 10px;
  width: 300px;
  border-bottom-right-radius: 10px;
  font-size: 1.4rem;
  cursor: pointer;
}

.dfo-main {
  width: 80%;
  margin: 0 auto;
}

.dfo-title {
  text-align: center;
}

.dfo-storename {
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}

.dfo-date {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.dfo-cashier-wrapper {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.dfo-cashier-pie {
  display: flex;
  align-items: center;
}

.dfo-cashier {
  border: 1px solid #4785c2;
  background-color: #272a3d;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 4px 4px 10px 2px rgba(181, 87, 173, 0.1),
    inset -4px -4px 6px 2px rgba(181, 87, 173, 0.1);
}

.dfo-cashier:hover {
  background-color: #202230;
  border: 1px solid #b658ae;
  box-shadow: 4px 4px 10px 2px rgba(181, 87, 173, 0.2),
    inset -4px -4px 6px 2px rgba(181, 87, 173, 0.2);
}

.dfo-cashier-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.dfo-icon {
  font-size: 3rem;
  margin-right: 10px;
}

.dfo-contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dfo-left {
  text-align: center;
}

.dfo-right {
  text-align: center;
}

.dfo-cashier-purple {
  text-align: center;
}
