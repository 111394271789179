.d-edits {
  overflow: hidden;
}

.d-edits-header {
  width: 100%;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.d-manual-entry {
  margin-top: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px;
  background-color: #1f1e2e;
  color: #fff;
}

.d-manual-entry-header {
  width: 90%;
  font-size: 1.2rem;
  font-size: bold;
  margin-left: 10px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.d-manual-entry-total-div {
  text-align: center;
}

.d-manual-entry-total {
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #2b3957;
  color: #fff;
  font-weight: bold;
  max-width: 75px;
  margin: auto;
}

.d-manual-entry-submit {
  text-align: center;
  margin-top: 5px;
  padding-top: 10px;
}

.d-edits-punchlist-header {
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.d-edits-punch-comment {
  width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-edits-total {
  text-align: center;
  font-weight: bold;
}

.d-edit-hover {
  cursor: pointer;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  background-color: transparent;
  color: #fff;
}

.period-closed {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ff0000;
  text-align: center;
  margin-bottom: 20px;
}

.punchlist-column-header {
  display: grid;
  grid-template-columns: 1fr 30px;
}
