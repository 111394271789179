.mtrans-m-store {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
}

.mtrans-m-cashier {
  text-align: center;
  font-weight: bold;
}

.mtrans-m-date {
  text-align: center;
  font-weight: bold;
}

.mtrans-m-trans {
  border: 1px solid;
  border-radius: 10px;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5px;
}

.mtrans-m-two {
  text-align: center;
}

.mtrans-m-three {
  text-align: right;
}
