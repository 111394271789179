.psas-line-description {
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
}

.psas-line-body {
  border-radius: 5px;
  height: 30px;
  background-color: rgb(160, 155, 155);
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    7px 9px 23px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 7px 9px 23px 5px rgba(0, 0, 0, 0);
}

.psas-line-last-thirty {
  /* background-color: limegreen; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
/* .psas-line-last-thirty-value {
  color: limegreen;
} */

.psas-line-last-sixty {
  background-color: orange;
}
.psas-line-last-sixty-value {
  color: orange;
}

.psas-line-last-ninety {
  background-color: slateblue;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.psas-line-last-ninety-value {
  color: slateblue;
}

.psas-line-body {
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.psas-line-legend {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.psas-line-legend-title {
  text-align: center;
  color: #fff;
}

.psas-line-legend-value {
  text-align: center;
}
