.status-title {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.status-current {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.status-change {
  width: 100%;
  text-align: center;
}

.status-result {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
}
