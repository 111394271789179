.clock-button-body {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}

.clock-button-body-main {
  width: 90%;
  height: 90%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}

.d-entry-button {
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
}

.d-entry-button:hover {
  border-color: #cb2dd6;
  cursor: pointer;
}

.d-entry-button-value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.d-entry-button-disabled {
  color: #aaa;
  border: 1px dashed #aaa;
  font-style: italic;
  border-radius: 10px;
}
