.freshop-widget {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 5px;
  background-color: #272a3d;
  color: #fff;
}

.freshop-widget-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 30px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.freshop-widget-header-records {
  font-size: 0.9rem;
  font-weight: normal;
  color: rgba(46, 116, 213, 0.7);
}

.freshop-widget-header-button {
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.freshop-price-mod-widget {
  border: 1px solid #fff !important;
}

.freshop-pricemod-cardheader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 50px;
}

.freshop-pricemod-header-info {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.freshop-pricemod-footer {
  font-size: 0.9rem;
  font-weight: normal;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.freshop-pricemod-row1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.freshop-pricemod-row2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.freshop-pricemod-iteminfo {
  text-align: center;
}

.freshop-fade-in {
  animation-name: freshopFadeIn;
  animation-duration: 1s;
}

.freshop-fade-out {
  animation-name: freshopFadeOut;
  animation-duration: 1s;
}

.error-item-title {
  width: 100%;
  text-align: center;
}

.card-unclosed-freshop-order {
  border: 1px solid #fff !important;
}

@keyframes freshopFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes freshopFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
