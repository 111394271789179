.m-trans-box {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.m-trans-table {
  width: 90%;
  margin: auto;
}

.m-trans-hdr-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.m-trans-type {
  text-align: left;
}

.m-trans-number {
  text-align: right;
}

.m-trans-hdr-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
}

.m-trans-date {
  text-align: left;
}

.m-trans-hdr-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
}

.m-trans-time {
  text-align: Left;
}

.m-trans-term {
  text-align: right;
}

.m-trans-invoice {
  text-align: right;
}

.m-trans-cashier {
  text-align: left;
  margin-top: 5px;
}

.m-trans-email {
  text-align: right;
}

.m-trans-hdr-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
}

.m-trans-reg {
  margin-top: 10px;
}

.m-trans-f01 {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
}

.m-trans-f1041 {
  width: 40%;
  font-size: 0.8rem;
  padding-left: 5px;
}

.m-trans-f64 {
  width: 10%;
  font-size: 0.8rem;
  text-align: right;
}

.m-trans-f65 {
  width: 10%;
  text-align: right;
  font-size: 0.8rem;
}

.m-trans-f67 {
  width: 10%;
  text-align: right;
  font-size: 0.8rem;
}

.m-trans-flags {
  width: 10%;
  text-align: right;
  font-size: 0.8rem;
}

.m-trans-ttl {
  margin-top: 10px;
}

.m-trans-ttl-table {
  width: 90%;
  margin: auto;
}

.m-trans-f1034 {
  text-align: left;
  width: 20%;
}

.m-trans-f1039 {
  text-align: left;
  width: 40%;
}

.m-trans-ttl-f64 {
  text-align: center;
  width: 20%;
}

.m-trans-ttl-f65 {
  text-align: right;
  width: 20%;
}

.m-trans-keystroke {
  font-size: 0.8rem;
  text-align: center;
  color: blue;
}
