.clock-container {
  background-color: #eee;
  padding: 20px;
}

.d-clock-info {
  background-color: #3b3b48;
  border: 1px solid purple;
  border-radius: 10px;
  padding: 10px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-clock-button {
  border: 1px solid purple;
  border-radius: 10px;
  padding: 5px;
  margin-top: 10px;
  text-align: center;
}

.d-clock-magic {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
}
