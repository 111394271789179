.vendorHeader {
  padding: 10px;
}

.vendorTitle {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.vendorBody {
  padding: 10px;
}
