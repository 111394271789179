.cash-nav-body {
  display: flex;
  justify-content: start;
  font-size: 1.5rem;
  cursor: pointer;
  border: 1px solid #4785c2;
  background-color: transparent;
  padding: 5px;
  border-bottom-right-radius: 10px;
  width: 250px;
  box-shadow: 4px 4px 10px 2px rgba(71, 133, 193, 0.1),
    inset -4px -4px 6px 2px rgba(71, 133, 193, 0.1);
}

.cash-nav-stores {
  margin-left: 10px;
  color: steelblue;
  opacity: 0.6;
}

.cash-nav-cashiers {
  margin-left: 10px;
  color: orange;
  opacity: 0.6;
}

.cash-nav-totalizers {
  margin-left: 10px;
  color: limegreen;
  opacity: 0.6;
}

.cash-nav-transactions {
  margin-left: 10px;
  color: violet;
  opacity: 0.6;
}
