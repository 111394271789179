.pa-h3 {
  background-color: #002767;
  color: #fff;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pa-header {
  margin-top: 5px;
}

.pa-customer-phone {
  text-align: center;
  font-weight: bold;
}

.pa-customer-activity-date {
  text-align: center;
  font-weight: bold;
}

.pa-top {
  margin-top: 10px;
  padding: 10px;
}

.pa-circle-container {
  width: 50%;
  margin: auto;
}

.pa-circle {
  position: relative;
  border-radius: 50%;
  border: 1px solid #002767;
  width: 100%;
  height: auto;
  padding-top: 100%;
}

.pa-circle-month {
  position: absolute;
  top: 40;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.pa-circle-points {
  position: absolute;
  top: 80;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.pa-circle-end {
  position: absolute;
  top: 120;
  width: 100%;
  text-align: center;
}

.pa-header-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.pa-header-details-left,
.pa-header-details-middle,
.pa-header-details-right {
  text-align: center;
}

.pa-header-details-left-zero-points
  .pa-header-details-middle-zero-points
  .pa-header-details-right-zero-points {
  font-weight: bold;
  font-size: 1.2rem;
}

.pa-header-details-left-points,
.pa-header-details-middle-points,
.pa-header-details-right-points {
  font-weight: bold;
  font-size: 1.2rem;
}

.pa-header-details-left-text
  .pa-header-details-middle-text
  .pa-header-details-right-text {
  text-align: center;
}

.pa-footer-back {
  font-size: 2rem;
  margin-left: 20px;
  cursor: pointer;
}

.pa-history-h4 {
  margin-left: 10px;
}

/* destop modifications */

.pa-circle-container-d {
  width: 25%;
  margin: auto;
}

.pa-circle-month-d {
  position: absolute;
  top: 30;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

.pa-circle-points-d {
  position: absolute;
  top: 90;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

.pa-circle-end-d {
  position: absolute;
  top: 160;
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
}
