.time-bars {
  border: 1px solid black;
  width: 90%;
  height: 200px;
  padding: 10px;
  background-color: #eee;
  margin: auto;
  margin-top: 20px;
}

.time-bars-row {
  height: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.time-box {
  border: 2px solid #fff;
  height: 100%;
  box-shadow: 6px 4px #ccc;
  margin-right: 5px;
  margin-left: 5px;
  padding: 0 !important;
}

.time-box-inner1 {
  border: 4px solid rgb(170, 201, 169);
  height: 100%;
  width: 100%;
  padding: 1px;
}

.time-box-inner2 {
  border: 2px solid #fff;
  height: 100%;
  width: 100%;
}

.time-box-inner3 {
  background-color: rgb(170, 201, 169);
  height: 100%;
  width: 100%;
  text-align: center;
}

.time-box-title {
  padding-top: 5px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  height: 33%;
}

.time-box-hours {
  height: 33%;
  background-color: #fff;
  color: #000;
  font-size: 1.5rem;
  padding-top: 5px;
}

.time-box-date {
  text-align: center;
  position: absolute;
  bottom: 0;
}

.time-box-footer {
  height: 33%;
  font-size: 1.4rem;
  color: #fff;
  padding-top: 10px;
  text-align: center;
}
