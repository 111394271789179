.report-spacer {
  height: 10px;
}

.rpt-name {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.ace-rewards-table {
  width: 90%;
  margin: auto;
}

.export-report {
  position: absolute;
  top: 100px;
  right: 0;
  margin-right: 40px;
  padding: 10px;
  border: 1px solid black;
}

.print-report {
  font-size: 1.3rem;
  cursor: pointer;
}

.csv-report {
  margin-left: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}
