.card-banner {
  border-radius: 10px;
  box-shadow: 5px 5px 0px rgba(35, 40, 55, 0.2),
    inset -5px -5px -3px rgba(35, 40, 55, 0.2);
}

.banner-comparator {
  margin-bottom: 20px;
  border: 1px solid black;
  background-color: rgba(40, 113, 181, 0.8);
  color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.29),
    inset -3px -3px 5px rgba(50, 0, 100, 0.29);
  padding: 5px;
}

.comparetor-title {
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.banner-title {
  width: 100%;
  text-align: center;
  color: #fff;
}

.banner-store-name {
  color: #fff;
  text-align: center;
}

.banner-store-version {
  color: #fff;
}

.banner-store-term {
  text-align: right;
}

.banner-compare {
  text-align: center;
}

.banner-left-for {
  height: 50px;
  width: 50px;
  background: rgb(213, 112, 217);
  background: radial-gradient(
    circle,
    rgba(213, 112, 217, 1) 1%,
    rgba(163, 62, 167, 1) 15%,
    rgba(171, 67, 175, 1) 35%,
    rgba(171, 67, 175, 1) 65%,
    rgba(163, 62, 167, 1) 85%,
    rgba(212, 112, 217, 1) 100%,
    rgba(168, 44, 194, 1) 100%,
    rgba(168, 44, 194, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.29),
    inset -5px -5px 3px rgba(0, 0, 0, 0.29);
}

.banner-right-for {
  height: 50px;
  width: 50px;
  background: rgb(122, 167, 209);
  background: radial-gradient(
    circle,
    rgba(122, 167, 209, 1) 1%,
    rgba(40, 113, 182, 1) 25%,
    rgba(40, 113, 182, 1) 75%,
    rgba(122, 167, 209, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.29),
    inset -5px -5px 3px rgba(0, 0, 0, 0.29);
}

.banner-icon-left {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.banner-icon-right {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.benner-icon-holder {
  margin: auto;
}

.card-divider {
  background-color: orange;
}

.compare-enable {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.compare-disable {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.left-side-title {
  font-size: 1.3rem;
  color: orange;
  border-bottom: 1px solid orange;
}

.right-side-title {
  font-size: 1.3rem;
  color: orange;
  border-bottom: 1px solid orange;
}

.left-side-delete {
  color: red;
  text-align: right;
  cursor: pointer;
  margin-top: 5px;
}
.compare-store {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3px;
}

.compare-disabled {
  opacity: 0.2;
  cursor: default;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.compare-enabled {
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.compare-delete-disabled {
  opacity: 0;
  cursor: default;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.compare-delete-enabled {
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
