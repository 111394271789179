.m-clockin-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
}

.m-clockin-button {
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
}

.m-outer-gravatar {
  width: 100%;
  margin: auto;
  /* border: 4px solid purple;
  border-radius: 50%; */
  padding: 20px;
}

.m-clockin-gravatar {
  border-radius: 50%;
  border: 2px solid purple;
  margin: auto;
}

.m-clockin-select-store-list {
  width: 100%;
  text-align: center;
  border: 1px solid purple;
  border-radius: 10px;
  margin-top: 20px;
  padding: 5px;
}

.c-button {
  border-radius: 10px;
  border: 1px solid purple;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  color: #fff;
  cursor: pointer;
}

.m-clockin-comment {
  margin-top: 10px;
}

.m-punchlist {
  margin-top: 10px;
}

.punch-list-table {
  font-size: 0.7rem !important;
}

.m-empty-punches {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}

.m-employeeid {
  width: 100%;
  text-align: center;
  color: #fff;
}

.m-employee-name {
  margin-top: 10px;
  width: 100%;
  color: #fff;
}

.m-total-hours-text {
  font-weight: bold;
  text-align: right;
}

.m-total-hours {
  font-weight: bold;
}
