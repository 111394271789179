.assign-title {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.assign-instructions {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.unassigned {
  border: 4px solid #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 3px 0px rgba(71, 131, 193, 0.3);
  padding: 0 !important;
}

.us-store {
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
  font-size: 1.1rem;
  color: #fff;
  cursor: hand;
  padding: 5px;
}

.us-store.over {
  border: 2px dashed #fff;
  background-color: limegreen;
}

.unassigned-stores {
  overflow: auto;
}

.assigned {
  border: 4px solid #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 3px 0px rgba(71, 131, 193, 0.3);
  padding: 0 !important;
}

.assigned-stores {
  overflow: auto;
}

.as-drop-zone {
  border: 1px solid #fff;
  border-radius: 5px;
  min-height: 60px;
  width: 100%;
  margin: 5px;
  box-shadow: inset -4px -4px 4px 0px rgba(181, 87, 173, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  width: 100%;
}

.as-drop-zone.over {
  opacity: 0.5;
  border: 2px solid rgba(71, 131, 193, 0.8);
  box-shadow: inset -4px -4px 4px 0px rgba(71, 131, 193, 0.2);
}

.as-trash {
  text-align: center;
}

.as-trash.over {
  border: 2px solid rgba(71, 131, 193, 0.8);
}

.trash-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.trash-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: orange;
}
