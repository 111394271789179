.predict-body {
  padding: 10px;
}

.predict-results-controls {
  width: 90%;
  margin: 0 auto;
  justify-content: space-around;
}

.coupon-selector {
  display: flex;
  justify-content: center;
}

.coupon-list {
  margin-top: 10px;
}

.coupon-predictions {
  margin-top: 10px;
}

.truncate {
  width: 200px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cat-truncate {
  width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-truncate {
  width: 225px;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.predict-selected-items {
  max-height: 300px;
  overflow: auto;
  background-color: #1f1e2e;
  border: 1px solid #4785c2;
  border-radius: 10px;
  padding: 5px;
}
