.TypeAheadDropDown {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.TypeAheadDropDown input {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #1f1e2e;
  box-shadow: none;
  font-size: 0.75rem;
  color: #fff;
  padding: 9px 18px 9px 18px;
  display: inline-block;
  border-color: #303958;
  border-radius: 0.4285rem;
  margin-left: 5px;
  border-width: 1px;
  border-style: solid;
  transition: border-color 250ms ease-in;
}
.TypeAheadDropDown input:focus {
  border-color: #446fd4 !important;
  outline-width: 0;
}
.TypeAheadDropDown ul::before {
  content: "";
}

.TypeAheadDropDown ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  border-top: 1px solid gray;
  position: absolute;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #1f1e2e;
  margin-left: 40px;
  opacity: 0;
  visibility: hidden;
  padding-left: 5px;
  padding-top: 5px;
  z-index: 100;
  transition: opacity 500ms ease-in;
  max-height: 500px;
  overflow-y: scroll;
}

.TypeAheadDropDown li span {
  display: block;
  cursor: pointer;
  padding-right: 5px;
}

.TypeAheadDropDown li:hover {
  background: #0077f7;
  text-decoration: none;
}

.tadd-text {
  font-weight: normal;
  padding: 0px 2px 1px;
  text-decoration: none;
  text-align: left;
}
