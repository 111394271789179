.roles-title {
  width: 100%;
  text-align: center;
}

.role-header {
}

.role-list {
}

.emptyroles {
  text-align: center;
}

.role-table {
  width: 90%;
  margin: auto;
}

.role-toggle-header {
  text-align: center;
}

.role-description {
  height: 100%;
  font-size: 1rem;
  font-weight: bold;
}

.role-description-span {
  margin-top: 10px;
}

.role-toggle {
  border: none !important;
  padding: 0 !important;
  text-align: center;
}

.user-spacer {
  margin-bottom: 10px;
}
