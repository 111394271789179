:root {
  --default-bg: #f7f7f7;
  --default-color: color(var(--default-bg) contrast(50%));
  --default-disabled-color: color(
    var(--default-color) blend(var(--default-bg) 70%)
  );
  --default-theme: #4eccc4;

  --dark-bg: #263238;
  --dark-color: color(var(--dark-bg) contrast(50%));
  --dark-disabled-color: color(var(--dark-color) blend(var(--dark-bg) 70%));
  --dark-theme: #50ccc4;

  --ios-bg: #f7f7f7;
  --ios-color: color(var(--ios-bg) contrast(50%));
  --ios-disabled-color: color(var(--ios-color) blend(var(--ios-bg) 70%));
  --ios-theme: #dbdbdb;

  --android-bg: #f5f5f5;
  --android-color: color(var(--android-bg) contrast(50%));
  --android-disabled-color: color(
    var(--android-color) blend(var(--android-bg) 70%)
  );
  --android-theme: #263238;

  --android-dark-bg: #292829;
  --android-dark-color: color(var(--android-dark-bg) contrast(50%));
  --android-dark-disabled-color: color(
    var(--android-dark-color) blend(var(--android-dark-bg) 70%)
  );
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.datepicker-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datepicker {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  font-size: 16px;
  text-align: center;
  font-family: arial, verdana, sans-serif;
  box-sizing: content-box;
  -webkit-font-smoothing: antialiased;
  user-select: none;
}
.datepicker-header {
  padding: 0.5em;
  min-height: 2em;
  line-height: 2em;
  font-size: 1.125em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.datepicker-navbar {
  padding: 0 0.5em 0.5em 0.5em;
  overflow: hidden;
}
.datepicker-navbar-btn {
  height: 2.5em;
  line-height: 2.5em;
  padding: 0 1em;
  cursor: pointer;
}
.datepicker-caption {
  display: flex;
  padding: 0.5em 0.25em;
}
.datepicker-caption-item {
  flex: 1;
  margin: 0 0.25em;
  height: 40px;
  line-height: 40px;
  font-size: 1.2em;
}
.datepicker-content {
  display: flex;
  padding: 0.5em 0.25em;
}
.datepicker-col-1 {
  flex: 1;
  margin: 0 0.25em;
}
.datepicker-viewport {
  height: 200px;
  position: relative;
  overflow: hidden;
}
.datepicker-viewport::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.datepicker-wheel {
  position: absolute;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  width: 100%;
}
.datepicker-scroll {
  list-style-type: none;
  padding: 0;
}
.datepicker-scroll > li {
  height: 40px;
  line-height: 40px;
  font-size: 1.375em;
  cursor: pointer;
}
.datepicker.default {
  background-color: var(--default-bg);
}

/* dark */
.datepicker-navbar-btn.dark {
  background-color: var(--dark-bg);
}

.datepicker-wheel {
  border-top: 1px solid var(--dark-theme);
  border-bottom: 1px solid var(--dark-theme);
}
.datepicker-caption-item {
  color: var(--dark-color);
}
li {
  color: var(--dark-color);
}
li.disabled {
  color: var(--dark-disabled-color);
}
.datepicker-navbar-btn {
  color: var(--dark-theme);
}

/* android */
.datepicker-caption + .datepicker-content.android-dark {
  background-color: var(--android-bg);
}
.datepicker-header {
  color: var(--android-theme);
  border-bottom: 2px solid var(--android-theme);
}
.datepicker-col-1 {
  margin: 0 0.625em;
}
.datepicker-viewport::after {
  background-image: linear-gradient(
    #f5f5f5,
    rgba(245, 245, 245, 0) 52%,
    rgba(245, 245, 245, 0) 48%,
    #f5f5f5
  );
}
.datepicker-wheel {
  border-top: 2px solid var(--android-theme);
  border-bottom: 2px solid var(--android-theme);
}
.datepicker-caption-item {
  color: var(--android-color);
}
li {
  font-size: 1.125em;
  color: var(--android-color);
}
li.disabled {
  color: var(--android-disabled-color);
}
.datepicker-navbar {
  display: flex;
  border-top: 1px solid #d9d4d4;
  padding: 0;
}
.datepicker-navbar-btn {
  padding: 0;
  color: #000;
  flex: 1;
}
.datepicker-navbar-btn:nth-child(2) {
  border-left: 1px solid #d9d4d4;
}
.datepicker-navbar-btn:nth-child(2).android-dark {
  background-color: var(--android-dark-bg);
}
.datepicker-viewport::after {
  background-image: linear-gradient(
    #282828,
    rgba(40, 40, 40, 0) 52%,
    rgba(40, 40, 40, 0) 48%,
    #282828
  );
}
.datepicker-caption-item {
  color: var(--android-dark-color);
}
li {
  color: var(--android-dark-color);
}
li.disabled {
  color: var(--android-dark-disabled-color);
}
.datepicker-navbar {
  border-color: #424542;
}
.datepicker-navbar-btn {
  color: #000;
}
.datepicker-navbar-btn:nth-child(2) {
  border-color: #424542;
}

.btn-dp-cancel {
  text-align: center;
  margin-left: 15px;
  border: 1px solid black;
  width: 100px;
  height: 90%;
  border-radius: 10px;
  margin-top: 3px;
}

.btn-dp-confirm {
  margin-right: 15px;
  text-align: center;
  border: 1px solid black;
  width: 100px;
  position: absolute;
  right: 0;
  border-radius: 10px;
}
