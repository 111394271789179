@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
.e-pivotview .e-expand::before {
  content: '\e859';
}

.e-pivotview .e-collapse::before {
  content: '\e83d';
}

.e-pivotview.e-rtl .e-expand::before {
  content: '\e854';
}

.e-pivotview .e-sort::before {
  content: '\e667';
}

.e-pivotview .e-pv-filter::before {
  content: '\e909';
}

.e-pivotview .e-pv-filtered::before {
  color: #e3165b;
  content: '\ebb3';
}

.e-pivotview .e-remove::before {
  content: '\e208';
}

.e-pivotview .e-selected-option-icon::before {
  content: '\ea84';
}

.e-pivotview .e-clear-filter-icon::before {
  content: '\ea82';
}

.e-pivotview .e-dropdown-icon::before {
  content: '\e83d';
}

.e-pivotview .e-export::before {
  content: '\e711';
}

.e-pivotview .e-new-report::before {
  content: '\e712';
}

.e-pivotview .e-save-report::before {
  content: '\e703';
}

.e-pivotview .e-saveas-report::before {
  content: '\e704';
}

.e-pivotview .e-rename-report::before {
  content: '\e70c';
}

.e-pivotview .e-remove-report::before {
  content: '\e706';
}

.e-pivotview .e-sub-total::before {
  content: '\e709';
}

.e-pivotview .e-grand-total::before {
  content: '\e707';
}

.e-pivotview .e-toolbar-fieldlist::before {
  content: '\e434';
}

.e-pivotview .e-toolbar-grid::before {
  content: '\e708';
}

.e-pivotview .e-toolbar-chart::before {
  content: '\e70f';
}

.e-pivotview .e-toolbar-formatting::before {
  content: '\e725';
}

.e-pivotview .e-pivot-button .e-edit::before {
  content: '\e891';
}

.e-pivotview .e-sort-ascend-icon::before {
  content: '\e734';
}

.e-pivotview .e-sort-descend-icon::before {
  content: '\e733';
}

.e-pivotview-pdf-export::before {
  content: '\e70d';
}

.e-pivotview-excel-export::before {
  content: '\e700';
}

.e-pivotview-csv-export::before {
  content: '\e701';
}

.e-pivotview-png-export::before {
  content: '\e715';
}

.e-pivotview-jpeg-export::before {
  content: '\e713';
}

.e-pivotview-svg-export::before {
  content: '\e716';
}

.e-mdx::before {
  content: '\e724';
}

.e-pivotview-select-icon::before {
  content: '\e614';
}

.e-pivotview-export::before {
  content: '\e711';
}

.e-pivotview-grid::before {
  content: '\e708';
}

.e-pivotview-expand::before {
  content: '\e556';
}

.e-pivotview-collapse::before {
  content: '\e554';
}

.e-pivot-format-menu::before {
  content: '\e728';
}

.e-pivot-number-format-menu::before {
  content: '\e70b';
}

.e-pivot-conditional-format-menu::before {
  content: '\e725';
}

.e-pivot-format-toolbar::before {
  content: '\e70b';
}

.e-pivotview-group::before {
  content: '\e74b';
}

.e-pivotview-ungroup::before {
  content: '\e74c';
}

.e-level-options .e-selected-level-icon::before {
  content: '\e614';
}

/*! PivotView layout */
.e-pivotview .e-value-field-settings,
.e-pivotview .e-member-editor-dialog,
.e-pivotview .e-group-field-settings {
  max-height: 450px !important;
}

.e-pivotview .e-value-field-settings,
.e-pivotview .e-member-editor-dialog,
.e-pivotview .e-pivot-format-dialog,
.e-pivotview .e-group-field-settings {
  border-radius: 4px;
  max-width: 400px;
  width: auto;
}

.e-pivotview .e-value-field-settings .e-dlg-header-content,
.e-pivotview .e-value-field-settings .e-footer-content,
.e-pivotview .e-member-editor-dialog .e-dlg-header-content,
.e-pivotview .e-member-editor-dialog .e-footer-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-header-content,
.e-pivotview .e-pivot-format-dialog .e-footer-content,
.e-pivotview .e-group-field-settings .e-dlg-header-content,
.e-pivotview .e-group-field-settings .e-footer-content {
  border-radius: 4px;
}

.e-pivotview .e-value-field-settings .e-dlg-header-content,
.e-pivotview .e-member-editor-dialog .e-dlg-header-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-header-content,
.e-pivotview .e-group-field-settings .e-dlg-header-content {
  border: 0;
  font-weight: normal;
}

.e-pivotview .e-value-field-settings .e-footer-content,
.e-pivotview .e-member-editor-dialog .e-footer-content,
.e-pivotview .e-pivot-format-dialog .e-footer-content,
.e-pivotview .e-group-field-settings .e-footer-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 8px;
  padding-top: 8px;
}

.e-pivotview .e-value-field-settings .e-footer-content .e-clear-filter-button,
.e-pivotview .e-member-editor-dialog .e-footer-content .e-clear-filter-button,
.e-pivotview .e-pivot-format-dialog .e-footer-content .e-clear-filter-button,
.e-pivotview .e-group-field-settings .e-footer-content .e-clear-filter-button {
  float: left;
  margin-left: -10px;
  margin: 0;
}

.e-pivotview .e-value-field-settings .e-footer-content .e-clear-filter-button.e-disable,
.e-pivotview .e-member-editor-dialog .e-footer-content .e-clear-filter-button.e-disable,
.e-pivotview .e-pivot-format-dialog .e-footer-content .e-clear-filter-button.e-disable,
.e-pivotview .e-group-field-settings .e-footer-content .e-clear-filter-button.e-disable {
  display: none;
}

.e-pivotview .e-value-field-settings .e-dlg-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content,
.e-pivotview .e-group-field-settings .e-dlg-content {
  overflow: hidden;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-editor-label-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-editor-label-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-editor-label-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-editor-label-wrapper {
  background-color: #f5f5f5;
  opacity: 56%;
  padding: 12px 10px 16px 20px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-editor-label-wrapper .e-editor-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-editor-label-wrapper .e-editor-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-editor-label-wrapper .e-editor-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-editor-label-wrapper .e-editor-label {
  color: #000;
  font: 500 13px Roboto;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  font-size: 13px;
  font-weight: 500;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title {
  -ms-flex: none;
      flex: none;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  -ms-flex: auto;
      flex: auto;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame {
  height: 18px;
  width: 18px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper {
  margin-bottom: 10px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-input-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-group-inerval-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper {
  padding-top: 20px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text {
  font-size: 13px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-value-field-div-content.e-group-field-div-content,
.e-pivotview .e-value-field-settings .e-dlg-content .e-group-field-div-content.e-group-field-div-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-field-div-content.e-group-field-div-content,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-group-field-div-content.e-group-field-div-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-field-div-content.e-group-field-div-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-group-field-div-content.e-group-field-div-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-field-div-content.e-group-field-div-content,
.e-pivotview .e-group-field-settings .e-dlg-content .e-group-field-div-content.e-group-field-div-content {
  margin-bottom: 10px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper {
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px 10px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort {
  box-shadow: none;
  margin-left: 10px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 16px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active {
  background-color: rgba(184, 184, 184, 0.9584);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-prompt,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-prompt,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-prompt,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-prompt {
  padding: 15px 20px;
  text-align: center;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div {
  display: inline-block;
  height: 160px;
  max-width: 400px;
  overflow: auto;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container {
  display: inline-table;
  height: 100%;
  width: 100%;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-olap-field-list-tree,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-olap-field-list-tree,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-olap-field-list-tree,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-olap-field-list-tree {
  overflow-x: auto;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul {
  margin-left: -35px;
  overflow: hidden;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon {
  margin: 0;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-disable,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-disable,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-member-editor-wrapper .e-disable,
.e-pivotview .e-group-field-settings .e-dlg-content .e-member-editor-wrapper .e-disable {
  display: none !important;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper,
.e-pivotview .e-group-field-settings .e-dlg-content .e-filter-tab-wrapper {
  border: none;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon,
.e-pivotview .e-group-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon {
  height: 14px;
  min-width: 14px;
  width: 14px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before,
.e-pivotview .e-group-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before {
  font-size: 14px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotview .e-group-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter,
.e-pivotview .e-group-field-settings .e-dlg-content .e-excelfilter {
  padding-top: 20px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div {
  height: 147px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter {
  height: 248px;
  overflow: auto;
  padding: 20px;
  padding-bottom: 0;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-filter-text-div {
  font-size: 13px;
  font-weight: 500;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-separator-div {
  padding-top: 20px;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-between-text-div {
  padding: 8px 0;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-disable,
.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-disable,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-disable,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-disable,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-disable,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-disable,
.e-pivotview .e-group-field-settings .e-dlg-content .e-label-filter .e-disable,
.e-pivotview .e-group-field-settings .e-dlg-content .e-value-filter .e-disable {
  display: none;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotview .e-value-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-member-editor-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotview .e-group-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotview .e-value-field-settings.e-excel-filter .e-editor-label-wrapper,
.e-pivotview .e-member-editor-dialog.e-excel-filter .e-editor-label-wrapper,
.e-pivotview .e-pivot-format-dialog.e-excel-filter .e-editor-label-wrapper,
.e-pivotview .e-group-field-settings.e-excel-filter .e-editor-label-wrapper {
  opacity: 56%;
  padding: 14px 10px 9px 20px;
}

.e-pivotview .e-value-field-settings.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-pivot-format-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotview .e-group-field-settings.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}

.e-pivotview .e-value-field-settings.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-pivot-format-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-group-field-settings.e-rtl .e-member-editor-wrapper ul {
  margin-left: 0;
  margin-right: -35px;
}

.e-pivotview .e-value-field-settings.e-rtl .e-footer-content .e-clear-filter-button,
.e-pivotview .e-member-editor-dialog.e-rtl .e-footer-content .e-clear-filter-button,
.e-pivotview .e-pivot-format-dialog.e-rtl .e-footer-content .e-clear-filter-button,
.e-pivotview .e-group-field-settings.e-rtl .e-footer-content .e-clear-filter-button {
  float: right;
  margin-right: -10px;
  margin: 0;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul {
  margin-left: 0;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop {
  margin: 0 5px;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret {
  display: none;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled {
  opacity: .5;
  pointer-events: none;
}

.e-pivotview .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-pivot-format-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotview .e-group-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul {
  margin-right: 0;
}

.e-pivotview .e-member-editor-dialog {
  min-width: 320px;
}

.e-pivotview .e-member-editor-dialog .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content {
  padding: 0;
}

.e-pivotview .e-value-field-settings .e-footer-content,
.e-pivotview .e-member-editor-dialog .e-footer-content {
  border-top: 1px solid #e0e0e0;
}

.e-pivotview .e-value-field-settings,
.e-pivotview .e-pivot-format-dialog {
  min-width: 300px;
}

.e-pivotview .e-value-field-settings .e-dlg-content,
.e-pivotview .e-pivot-format-dialog .e-dlg-content {
  overflow: auto;
}

.e-pivotview .e-pivotchart-type-dialog {
  max-width: 350px;
}

.e-pivotview .e-pivotchart-type-dialog .e-dlg-content {
  overflow: visible;
}

.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-wrapper,
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-text {
  padding-bottom: 20px;
}

.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-wrapper .e-chart-type-option-text,
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-text .e-chart-type-option-text {
  padding-bottom: 5px;
}

.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-wrapper .e-input-group.e-control-wrapper,
.e-pivotview .e-pivotchart-type-dialog .e-chart-type-option-text .e-input-group.e-control-wrapper {
  padding: 1px;
}

.e-pivotview .e-pivotchart-type-dialog .e-dialog-multiple-axis,
.e-pivotview .e-pivotchart-type-dialog .e-dialog-show-legend {
  width: 50%;
}

.e-pivotview .e-pivotchart-type-dialog .e-icons.e-frame {
  height: 18px;
  width: 18px;
}

.e-pivotview .e-pivot-format-dialog {
  max-height: 500px !important;
  max-width: 350px;
}

.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer {
  padding: 0 20px;
}

.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-value-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-custom-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-grouping-lable,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-decimal-lable {
  font-weight: 500;
}

.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-value-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-custom-text,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-grouping-drop,
.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer .e-pivot-format-decimal-drop {
  margin-bottom: 20px;
}

.e-pivotview .e-pivot-toolbar {
  border-bottom: 0;
  border-style: solid;
}

.e-pivotview .e-pivot-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  font-size: 16px;
}

.e-pivotview .e-pivot-toolbar .e-menu-wrapper {
  background-color: transparent;
  border: none;
}

.e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item {
  height: 40px;
  padding-left: 10px;
}

.e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item .e-menu-icon {
  margin-left: 0;
  margin-right: 0;
  font-size: 16px;
}

.e-pivotview .e-pivot-toolbar .e-toolbar-item.e-template {
  padding: 0 !important;
}

.e-pivotview .e-pivot-toolbar .e-reportlist-label {
  float: left;
  font-weight: 500;
}

.e-pivotview .e-pivot-toolbar .e-reportlist-drop {
  float: right;
  margin: 0 8px;
}

.e-pivotview .e-pivot-toolbar.e-rtl .e-reportlist-label {
  float: right;
}

.e-pivotview .e-pivot-toolbar.e-rtl .e-reportlist-drop {
  float: left;
}

.e-pivotview .e-pivot-toolbar.e-rtl .e-menu-wrapper ul .e-menu-item {
  padding-left: 30px;
  padding-right: 10px;
}

.e-pivotview-disable-icon {
  visibility: hidden;
}

.e-pivotview-report-inner {
  padding-top: 10px;
}

.e-pivotview-report-dialog {
  max-width: 350px;
}

.e-mdx-query-content {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 200px;
  margin-top: 5px;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}

.e-pivotview-mdx-dialog {
  max-width: 500px;
}

.e-report-outer {
  display: inline-block;
  width: 100%;
}

.e-pivotview-report-label {
  float: left;
  margin-top: 8px;
  width: 40%;
}

.e-pivotview-report-input {
  float: right;
  width: 60% !important;
}

.e-rtl .e-pivotview-report-input {
  float: left;
}

.e-rtl .e-pivotview-report-label {
  float: right;
}

.e-pivotview-pdf-export,
.e-pivotview-excel-export,
.e-pivotview-csv-export,
.e-pivotview-png-export,
.e-pivotview-jpeg-export,
.e-pivotview-svg-export {
  font-size: 18px !important;
}

.e-drillthrough-dialog .e-drillthrough-body-header-container {
  margin-bottom: 20px;
}

.e-drillthrough-dialog .e-drillthrough-body-header {
  color: #000;
  font-weight: 600;
  opacity: 0.87;
}

.e-drillthrough-dialog .e-drillthrough-body-header-value {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 10px;
  margin-right: 20px;
}

.e-drillthrough-dialog .e-drillthrough-body-header-common {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  display: inline-block;
  flex-wrap: wrap;
}

.e-bigger .e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item,
.e-bigger.e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item,
.e-bigger .e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item,
.e-bigger.e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item {
  height: 52px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div {
  height: 125px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger .e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger.e-pivotview .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 18px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-header-content .e-clear-filter-button.e-small {
  font-size: 14px;
  top: 0;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div {
  height: 100px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter {
  height: 220px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-text-div {
  font-size: 14px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-separator-div {
  padding-top: 24px;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-dlg-content .e-value-filter .e-between-text-div {
  padding: 10px 0;
}

.e-bigger .e-pivotview .e-member-editor-dialog.e-excel-filter,
.e-bigger .e-pivotview .e-value-field-settings.e-excel-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog.e-excel-filter,
.e-bigger.e-pivotview .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotview .e-value-field-settings.e-excel-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog.e-excel-filter,
.e-bigger .e-pivotview .e-member-editor-dialog.e-excel-filter,
.e-bigger .e-pivotview .e-value-field-settings.e-excel-filter,
.e-bigger .e-pivotview .e-pivot-format-dialog.e-excel-filter,
.e-bigger.e-pivotview .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotview .e-value-field-settings.e-excel-filter,
.e-bigger.e-pivotview .e-pivot-format-dialog.e-excel-filter {
  min-width: 350px;
}

.e-bigger .e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer,
.e-bigger .e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer,
.e-bigger.e-pivotview .e-pivot-format-dialog .e-pivot-format-dialog-outer {
  padding: 0 20px 10px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content {
  padding: 0 20px 10px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-checkbox-wrapper .e-frame,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-checkbox-wrapper .e-frame {
  height: 22px;
  width: 22px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-caption-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-caption-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-start-option-wrapper .e-checkbox-wrapper .e-label {
  font-size: 14px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  font-size: 14px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper {
  padding-top: 24px;
}

.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger .e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-value-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-value-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-field-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-caption-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-type-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-field-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-base-item-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-input-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-end-option-wrapper .e-group-inerval-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-type-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-input-option-text,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-checkbox-wrapper .e-label,
.e-bigger.e-pivotview .e-group-field-settings .e-group-field-div-content .e-group-option-wrapper .e-group-interval-option-wrapper .e-group-inerval-option-text {
  font-size: 14px;
}

.e-bigger .e-pivotview-report-dialog .e-pivotview-report-label,
.e-bigger.e-pivotview-report-dialog .e-pivotview-report-label,
.e-bigger .e-pivotview-report-dialog .e-pivotview-report-label,
.e-bigger.e-pivotview-report-dialog .e-pivotview-report-label {
  margin-top: 12px;
}

/*! PivotView theme */
.e-pivotview .e-nextspan {
  display: inline-block;
  width: 18px;
}

.e-pivotview .e-lastspan {
  display: inline-block;
  padding-top: 10px;
  width: 12px;
}

.e-pivotview .e-grid.e-gridhover tr[role='row']:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: rgba(0, 0, 0, 0);
}

.e-pivotview .e-rowsheader .e-cellvalue {
  margin-left: 10px;
}

.e-pivotview .e-valuescontent {
  padding-right: 8px;
  text-align: right;
}

.e-pivotview .e-grid
.e-rowcell {
  font-size: 13px;
  padding-left: 8px;
}

.e-pivotview .e-gtot,
.e-pivotview .e-gtot.e-rowsheader,
.e-pivotview .e-gtot.e-columnsheader,
.e-pivotview .e-grid .e-content,
.e-pivotview .e-grid .e-gridheader,
.e-pivotview .e-headercell {
  background-color: #f5f5f5;
}

.e-pivotview .e-grid .e-gridheader {
  border-bottom-width: 1px;
}

.e-pivotview .e-gtot {
  font-weight: 500;
}

.e-pivotview .e-gtot
.e-cellvalue {
  color: #000 87%;
}

.e-pivotview .e-rowsheader,
.e-pivotview .e-columnsheader {
  background-color: #fafafa;
}

.e-pivotview .e-rowsheader .e-cellvalue {
  margin-left: 8px;
  margin-top: 5px;
}

.e-pivotview .e-pivot-conditional-empty-format {
  margin: 10px;
}

.e-pivotview .e-member-editor-dialog,
.e-pivotview .e-olap-editor-dialog {
  visibility: hidden;
}

.e-pivotview .e-toolbar .e-toolbar-items {
  margin-left: 20px;
}

.e-pivotview .e-rtl.e-toolbar .e-toolbar-items {
  margin-left: 0;
  margin-right: 20px;
}

.e-pivotview .e-pivot-format-dialog .e-pivot-format-table {
  width: 100%;
}

.e-pivotview .e-grid .e-rowsheader.e-active,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground,
.e-pivotview .e-grid .e-columnsheader.e-active,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground,
.e-pivotview .e-grid .e-rowsheader.e-active,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground,
.e-pivotview .e-grid .e-columnsheader.e-active,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground {
  background: #e0e0e0 !important;
  color: #000 !important;
}

.e-pivotview .e-grid .e-rowsheader.e-active .e-stackedheadercelldiv, .e-pivotview .e-grid .e-rowsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-active .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-active .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-active .e-icons,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-active .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-active .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-active .e-icons,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-stackedheadercelldiv,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-cellvalue,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-icons {
  color: #000 !important;
}

.e-pivotview .e-grid .e-rowsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-cellselectionbackground .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-cellselectionbackground .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-rowsheader.e-selectionbackground .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-active .e-headertext,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground .e-headertext {
  color: #000 !important;
}

.e-pivotview .e-drop-indicator {
  display: block;
}

.e-pivotview div.e-cellvalue {
  margin-top: 5px;
}

.e-pivotview .e-sortfilterdiv {
  margin: -15px !important;
}

.e-pivotview .e-pivotchart > svg, .e-pivotview .e-pivotchart-inner > svg {
  border: 1px solid #e0e0e0;
}

.e-pivotview .e-pivotchart.e-ltr {
  direction: ltr;
}

.e-pivotview .e-grid .e-columnheader.e-wrap .e-sortfilterdiv,
.e-pivotview .e-grid.e-wrap .e-columnheader .e-sortfilterdiv {
  margin: -18px !important;
}

.e-pivotview .e-grid .e-icons {
  color: rgba(0, 0, 0, 0.54) !important;
}

.e-pivotview .e-grid .e-icons:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-grid {
  border-color: #e0e0e0;
}

.e-pivotview .e-grid .e-rowsheader {
  padding-left: 8px;
}

.e-pivotview .e-grid .e-rowsheader.e-active .e-icons {
  color: rgba(0, 0, 0, 0.54) !important;
}

.e-pivotview .e-grid .e-headercelldiv,
.e-pivotview .e-grid .e-columnsheader {
  padding-left: 8px !important;
}

.e-pivotview .e-frozenheader > .e-table,
.e-pivotview .e-frozencontent > .e-table {
  border-left-color: #e0e0e0 !important;
  border-right-color: #e0e0e0 !important;
}

.e-pivotview .e-stackedheadercelldiv.e-cellvalue {
  padding-left: 8px !important;
  margin-top: 5px;
}

.e-pivotview .e-stackedheadercelldiv {
  padding: 0 0 0 1px !important;
}

.e-pivotview .e-stackedheadercelldiv,
.e-pivotview .e-headertext,
.e-pivotview .e-rowsheader .e-cellvalue {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 500;
}

.e-pivotview .e-stackedheadercelldiv .e-hyperlinkcell,
.e-pivotview .e-headertext .e-hyperlinkcell,
.e-pivotview .e-rowsheader .e-cellvalue .e-hyperlinkcell {
  cursor: pointer;
}

.e-pivotview .e-valuescontent .e-cellvalue .e-hyperlinkcell {
  cursor: pointer;
}

.e-pivotview .e-valuescontent {
  opacity: 0.87 !important;
}

.e-pivotview .e-rowcell {
  opacity: 1;
}

.e-pivotview .e-rowcell .e-cellvalue {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.e-pivotview .e-grouping-bar {
  display: -ms-flexbox;
  display: flex;
}

.e-pivotview .e-grouping-bar .e-right-axis-fields {
  -ms-flex: auto;
      flex: auto;
}

.e-pivotview .e-grouping-bar .e-left-axis-fields,
.e-pivotview .e-grouping-bar .e-right-axis-fields {
  height: auto;
}

.e-pivotview .e-grouping-bar .e-left-axis-fields {
  min-width: 250px;
}

.e-pivotview .e-grouping-bar .e-axis-row,
.e-pivotview .e-grouping-bar .e-axis-column,
.e-pivotview .e-grouping-bar .e-axis-value,
.e-pivotview .e-grouping-bar .e-axis-filter {
  background: #f5f5f5;
  display: -ms-flexbox;
  display: flex;
}

.e-pivotview .e-grouping-bar .e-left-axis-fields {
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
  border-right-width: 0;
}

.e-pivotview .e-grouping-bar .e-right-axis-fields {
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
}

.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-filter {
  border-bottom: 1px solid #e0e0e0;
}

.e-pivotview .e-group-values,
.e-pivotview .e-group-columns,
.e-pivotview .e-group-filters,
.e-pivotview .e-group-rows {
  background: #f5f5f5;
  vertical-align: top;
}

.e-pivotview .e-group-values.e-drag-restrict,
.e-pivotview .e-group-columns.e-drag-restrict,
.e-pivotview .e-group-filters.e-drag-restrict,
.e-pivotview .e-group-rows.e-drag-restrict {
  cursor: no-drop;
}

.e-pivotview .e-group-values .e-draggable-prompt,
.e-pivotview .e-group-columns .e-draggable-prompt,
.e-pivotview .e-group-filters .e-draggable-prompt,
.e-pivotview .e-group-rows .e-draggable-prompt {
  color: #000;
  display: inline-block;
  line-height: 22px;
  margin-top: 4px;
  padding-left: 8px;
  pointer-events: none;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.e-pivotview .e-group-filters,
.e-pivotview .e-group-values,
.e-pivotview .e-group-columns,
.e-pivotview .e-group-rows {
  display: inline-table;
  -ms-flex: auto;
      flex: auto;
  height: 30px;
  min-height: 30px;
  overflow: hidden;
  padding-left: 8px;
  scroll-behavior: smooth;
}

.e-pivotview .e-group-filters .e-pvt-btn-div,
.e-pivotview .e-group-values .e-pvt-btn-div,
.e-pivotview .e-group-columns .e-pvt-btn-div,
.e-pivotview .e-group-rows .e-pvt-btn-div {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  padding: 3px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 8px;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-indent-div,
.e-pivotview .e-group-values .e-pvt-btn-div .e-indent-div,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-indent-div,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-indent-div {
  pointer-events: none;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-drop-indicator,
.e-pivotview .e-group-values .e-pvt-btn-div .e-drop-indicator,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-drop-indicator,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-drop-indicator {
  pointer-events: none;
  width: 2px;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-drop-indicator.e-drop-hover {
  border-left: 1px dashed #9e9e9e;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  box-shadow: none;
  color: #000;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  height: 24px;
  line-height: .9em;
  padding: 4px 8px;
  text-transform: none;
  vertical-align: bottom;
  z-index: 1;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-list-selected {
  opacity: .5;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:hover .e-edit,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:hover .e-edit,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:hover .e-edit,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused {
  background-color: #9e9e9e;
  color: #000;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus, .e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active {
  background-color: #9e9e9e;
  color: #000;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:focus .e-edit, .e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content {
  background-color: transparent;
  cursor: move;
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  margin-right: 4px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-content.e-disable-drag,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-content.e-disable-drag,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-content.e-disable-drag,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content.e-disable-drag {
  cursor: auto;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-draggable {
  height: inherit;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-drag {
  display: none;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54) !important;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  margin-left: 4px;
  width: 18px;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 12px;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}

.e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}

.e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button {
  max-width: 230px;
}

.e-pivotview .e-group-filters,
.e-pivotview .e-group-columns {
  width: 100%;
}

.e-pivotview .e-group-row {
  padding: 0 !important;
}

.e-pivotview .e-group-row .e-group-rows {
  display: table;
  -ms-flex: none;
      flex: none;
  width: 100%;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div {
  max-width: unset;
  width: 100%;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-indent-div {
  pointer-events: none;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button {
  padding: 4px 8px;
  width: 180px;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover {
  background-color: #bdbdbd;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:hover .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused {
  background-color: #9e9e9e;
  color: #000;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button.e-btn-focused .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus, .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active {
  background-color: #9e9e9e;
  color: #000;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:focus .e-edit, .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button:active .e-edit {
  color: rgba(0, 0, 0, 0.87) !important;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  height: 18px;
  margin-left: 4px;
  width: 18px;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 12px;
  width: 18px;
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}

.e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}

.e-pivotview .e-axis-value {
  display: inline-block;
  height: 100%;
}

.e-pivotview .e-axis-value .e-group-values {
  height: 100%;
  padding-bottom: 0;
}

.e-pivotview .e-axis-value .e-group-chart-values {
  display: inline-block;
  height: 28px;
}

.e-pivotview .e-axis-value .e-group-chart-values-drodown {
  font-size: 13px;
  height: 25px;
}

.e-pivotview .e-axis-column .e-group-chart-columns-dropdown {
  font-size: 13px;
  height: 25px;
  margin: 0;
}

.e-pivotview .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon {
  min-height: initial;
}

.e-pivotview .e-axis-column .e-group-chart-columns-dropdown input.e-input {
  padding-bottom: 7px;
}

.e-pivotview .e-chart-grouping-bar .e-axis-row {
  height: 50% !important;
}

.e-pivotview .e-chart-grouping-bar .e-axis-value {
  height: auto !important;
  padding-left: 6px;
  border-bottom: 1px solid #e0e0e0;
}

.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-multi-values {
  padding-left: 2px;
}

.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown {
  margin: 0;
}

.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon {
  min-height: initial;
}

.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input {
  padding-bottom: 7px;
}

.e-pivotview .e-icons {
  height: auto;
  width: auto;
}

.e-pivotview .e-collapse::before {
  font-size: 10px;
}

.e-pivotview .e-expand,
.e-pivotview .e-collapse {
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-top: 5px;
  text-align: center;
  width: 18px;
}

.e-pivotview th .e-expand,
.e-pivotview th .e-collapse {
  float: left;
  font-size: 10px !important;
  margin-left: 0px;
  margin-top: 8px;
}

.e-pivotview .e-expand::before {
  font-size: 10px;
}

.e-pivotview.e-rtl .e-expand::before,
.e-pivotview.e-rtl .e-collapse::before {
  margin-left: 0;
}

.e-pivotview.e-rtl th .e-expand,
.e-pivotview.e-rtl th .e-collapse {
  float: right;
  margin-right: -8px;
}

.e-pivotview.e-rtl .e-grid .e-rowsheader {
  padding-right: 8px !important;
}

.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 8px !important;
}

.e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-left: 1.8em !important;
  padding-right: 8px !important;
}

.e-pivotview.e-rtl .e-rowsheader .e-cellvalue {
  margin-left: 0;
  margin-right: 7px;
}

.e-pivotview.e-rtl .e-valuescontent {
  text-align: left;
}

.e-pivotview.e-rtl .e-grouping-bar .e-left-axis-fields {
  border-left-width: 0;
  border-right-width: 1px;
}

.e-pivotview.e-rtl .e-grouping-bar .e-right-axis-fields {
  border-left-width: 1px;
}

.e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters {
  padding-left: 0;
  padding-right: 8px;
}

.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div {
  padding-left: 8px;
  padding-right: 0;
}

.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-content {
  margin-left: 4px;
  text-align: right;
}

.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-grouping-bar .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 4px;
}

.e-pivotview.e-rtl .e-group-row .e-group-rows {
  padding-left: 0;
  padding-right: 8px;
}

.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div {
  padding-left: 8px;
  padding-right: 0;
}

.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content {
  margin-left: 4px;
  margin-right: 0;
  text-align: right;
}

.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotview.e-rtl .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 7px;
}

.e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue {
  padding-right: 8px !important;
}

.e-pivotview.e-device .e-grouping-bar .e-pivot-button {
  line-height: 1em !important;
}

.e-pivotview.e-device .e-grouping-bar .e-group-filters .e-pvt-btn-div .e-pivot-button,
.e-pivotview.e-device .e-grouping-bar .e-group-values .e-pvt-btn-div .e-pivot-button {
  max-width: 155px;
}

.e-pivotview.e-device .e-grouping-bar .e-group-columns .e-pvt-btn-div .e-pivot-button {
  max-width: 200px;
}

.e-pivotview.e-device .e-group-row .e-group-rows .e-pvt-btn-div .e-indent-div {
  width: 0 !important;
}

.e-pivotview.e-device .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button {
  width: 155px;
}

.e-pivottooltipwrap {
  opacity: 1;
}

.e-drillthrough-dialog {
  visibility: hidden;
}

.e-pivottooltip {
  border-radius: 2px;
  padding: 3px 4px;
}

.e-pivottooltip p {
  color: #fff;
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.e-pivottooltip p.e-tooltipcontent {
  margin-left: 5px;
}

.e-pivottooltiptemplate.e-tooltip-wrap {
  border: medium none transparent;
  max-width: none;
  min-width: 0;
  opacity: 1;
}

.e-pivottooltiptemplate .e-tip-content {
  overflow-x: visible;
  padding: unset;
  word-break: normal;
}

.e-rtl .e-pivottooltip p.e-tooltipcontent {
  margin-left: 0;
  margin-right: 5px;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown {
  margin: 0;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown .e-input-group-icon {
  min-height: initial;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-value .e-group-chart-values-drodown input.e-input {
  padding-bottom: 10px;
  padding-top: 0;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown {
  margin: 0;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown .e-input-group-icon {
  min-height: initial;
}

.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input,
.e-bigger .e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input,
.e-bigger.e-pivotview .e-chart-grouping-bar .e-axis-column .e-group-chart-columns-dropdown input.e-input {
  padding-bottom: 10px;
  padding-top: 0;
}

.e-bigger .e-pivotview .e-grid .e-rowsheader,
.e-bigger.e-pivotview .e-grid .e-rowsheader,
.e-bigger .e-pivotview .e-grid .e-rowsheader,
.e-bigger.e-pivotview .e-grid .e-rowsheader {
  padding-left: 8px !important;
}

.e-bigger .e-pivotview .e-grid .e-headercelldiv,
.e-bigger.e-pivotview .e-grid .e-headercelldiv,
.e-bigger .e-pivotview .e-grid .e-headercelldiv,
.e-bigger.e-pivotview .e-grid .e-headercelldiv {
  padding-left: 8px !important;
}

.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader,
.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader {
  padding-left: 8px !important;
}

.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue {
  font-size: 13px !important;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div {
  height: 30px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button {
  height: 30px;
  line-height: 1em;
  padding: 6px 12px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-content {
  font-size: 13px;
  margin-right: 8px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-edit {
  font-size: 14px;
  height: 20px;
  width: 20px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-grouping-bar .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview .e-grouping-bar .e-group-rows,
.e-bigger .e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview .e-grouping-bar .e-group-rows {
  height: 36px;
  min-height: 36px;
  padding-left: 10px;
}

.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview .e-grouping-bar .e-group-values {
  height: 100%;
}

.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger .e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-left-axis-fields .e-axis-nav-div .e-right-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-left-nav-wrapper,
.e-bigger.e-pivotview .e-grouping-bar .e-right-axis-fields .e-axis-nav-div .e-right-nav-wrapper {
  width: 24px;
}

.e-bigger .e-pivotview .e-group-rows,
.e-bigger.e-pivotview .e-group-rows,
.e-bigger .e-pivotview .e-group-rows,
.e-bigger.e-pivotview .e-group-rows {
  padding-left: 10px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div {
  height: 30px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button {
  height: 30px;
  line-height: 1em;
  padding: 6px 12px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-content {
  font-size: 13px;
  margin-right: 8px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-edit {
  font-size: 14px;
  height: 20px;
  width: 20px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}

.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon {
  margin-top: 1px;
}

.e-bigger .e-pivotview .e-sortfilterdiv,
.e-bigger.e-pivotview .e-sortfilterdiv,
.e-bigger .e-pivotview .e-sortfilterdiv,
.e-bigger.e-pivotview .e-sortfilterdiv {
  margin: -17px -10px !important;
}

.e-bigger .e-pivotview .e-grid .e-columnheader.e-wrap .e-sortfilterdiv,
.e-bigger .e-pivotview .e-grid.e-wrap .e-columnheader .e-sortfilterdiv,
.e-bigger.e-pivotview .e-grid .e-columnheader.e-wrap .e-sortfilterdiv,
.e-bigger.e-pivotview .e-grid.e-wrap .e-columnheader .e-sortfilterdiv,
.e-bigger .e-pivotview .e-grid .e-columnheader.e-wrap .e-sortfilterdiv,
.e-bigger .e-pivotview .e-grid.e-wrap .e-columnheader .e-sortfilterdiv,
.e-bigger.e-pivotview .e-grid .e-columnheader.e-wrap .e-sortfilterdiv,
.e-bigger.e-pivotview .e-grid.e-wrap .e-columnheader .e-sortfilterdiv {
  margin: -18px -15px !important;
}

.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-bigger .e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-bigger.e-pivotview .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container {
  height: 125px;
}

.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger .e-pivotview .e-stackedheadercelldiv,
.e-bigger .e-pivotview .e-headertext,
.e-bigger .e-pivotview .e-rowsheader .e-cellvalue,
.e-bigger.e-pivotview .e-stackedheadercelldiv,
.e-bigger.e-pivotview .e-headertext,
.e-bigger.e-pivotview .e-rowsheader .e-cellvalue {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader,
.e-bigger .e-pivotview .e-grid .e-columnsheader,
.e-bigger.e-pivotview .e-grid .e-columnsheader {
  padding-right: 16px !important;
}

.e-bigger .e-pivotview .e-rowcell,
.e-bigger.e-pivotview .e-rowcell,
.e-bigger .e-pivotview .e-rowcell,
.e-bigger.e-pivotview .e-rowcell {
  font-size: 13px;
}

.e-bigger .e-pivotview.e-rtl .e-grid .e-rowsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-rowsheader,
.e-bigger .e-pivotview.e-rtl .e-grid .e-rowsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-rowsheader {
  padding-right: 10px !important;
}

.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-right: 16px !important;
}

.e-bigger .e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger .e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger .e-pivotview.e-rtl .e-grid .e-columnsheader,
.e-bigger.e-pivotview.e-rtl .e-grid .e-headercelldiv,
.e-bigger.e-pivotview.e-rtl .e-grid .e-columnsheader {
  padding-left: 1.8em !important;
  padding-right: 8px !important;
}

.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-filters,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger .e-pivotview.e-rtl .e-grouping-bar .e-group-filters,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-columns,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-values,
.e-bigger.e-pivotview.e-rtl .e-grouping-bar .e-group-filters {
  padding-left: 0;
  padding-right: 10px;
}

.e-bigger .e-pivotview.e-rtl .e-group-row .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-group-row .e-group-rows,
.e-bigger .e-pivotview.e-rtl .e-group-row .e-group-rows,
.e-bigger.e-pivotview.e-rtl .e-group-row .e-group-rows {
  padding-left: 0;
  padding-right: 10px;
}

.e-bigger .e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue,
.e-bigger.e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue,
.e-bigger .e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue,
.e-bigger.e-pivotview.e-rtl .e-stackedheadercelldiv.e-cellvalue {
  padding-right: 8px !important;
}

.e-content-placeholder.e-pivotview.e-placeholder-pivotview {
  background-size: 100% 200px;
}

/*! component's theme wise override definitions and variables */
/*! component's common definitions and variables */
/*! field-list component icons */
.e-pivotfieldlist .e-select-table::before {
  content: '\e434';
}

.e-pivot-formatting-dialog .e-format-delete-icon::before {
  content: '\ea7f';
}

.e-pivot-formatting-dialog .e-add-icon::before {
  content: '\e823';
}

.e-pivot-formatting-dialog .e-colorpicker-wrapper .e-format-back-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before {
  content: '\e35c';
}

.e-pivot-formatting-dialog .e-colorpicker-wrapper .e-format-font-color + .e-split-btn-wrapper .e-split-btn .e-selected-color::before {
  content: '\e35f';
}

.e-level-options .e-selected-level-icon::before {
  content: '\e614';
}

.e-pivot-calc-dialog-div .e-drag::before {
  content: '\e330';
}

.e-pivot-calc-dialog-div .e-pivot-all-field-title-wrapper .e-info.e-icons::before {
  content: '\e607';
}

.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-edited.e-icons::before {
  content: '\e7a3';
}

.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-edit.e-icons::before {
  content: '\e891';
}

.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-remove-report.e-icons::before {
  content: '\e706';
}

.e-pivot-calc-dialog-div .e-remove-report.e-icons::before {
  content: '\e706';
}

.e-pivot-calc-dialog-div .e-list-item .e-text-content .e-format.e-icons::before {
  content: '\e22f';
}

.e-pivot-calc-dialog-div .e-measureGroupCDB-icon::before {
  content: '\ea76' !important;
}

.e-pivot-calc-dialog-div .e-measure-icon::before {
  content: '\ea76' !important;
}

.e-pivot-calc-dialog-div .e-folderCDB-icon::before {
  content: '\e60b' !important;
}

.e-pivot-calc-dialog-div .e-folderCDB-open-icon::before {
  content: '\e65f' !important;
}

.e-pivot-calc-dialog-div .e-dimensionCDB-icon::before {
  content: '\e65b' !important;
}

.e-pivot-calc-dialog-div .e-kpiCDB-icon::before {
  content: '\e664' !important;
}

.e-pivot-calc-dialog-div .e-kpiGoal-icon::before {
  content: '\e664' !important;
}

.e-pivot-calc-dialog-div .e-kpiStatus-icon::before {
  content: '\e664' !important;
}

.e-pivot-calc-dialog-div .e-kpiTrend-icon::before {
  content: '\e664' !important;
}

.e-pivot-calc-dialog-div .e-kpiValue-icon::before {
  content: '\e664' !important;
}

.e-pivot-calc-dialog-div .e-namedSetCDB-icon::before {
  content: '\e65d' !important;
}

.e-pivot-calc-dialog-div .e-hierarchyCDB-icon::before {
  content: '\e663' !important;
}

.e-pivot-calc-dialog-div .e-attributeCDB-icon::before {
  content: '\e65c' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-0-icon::before {
  content: '\e65a' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-1-icon::before {
  content: '\e65a' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-2-icon::before {
  content: '\e662' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-3-icon::before {
  content: '\e65e' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-4-icon::before {
  content: '\e660' !important;
}

.e-pivot-calc-dialog-div .e-hierarchy-level-5-icon::before {
  content: '\e661' !important;
}

.e-pivot-calc-dialog-div .e-calcMemberGroupCDB::before {
  content: '\e729' !important;
}

.e-pivot-calc-dialog-div .e-calc-measure-icon::before {
  content: '\ea76' !important;
}

.e-pivot-calc-dialog-div .e-calc-dimension-icon::before {
  content: '\e65b' !important;
}

.e-pivot-calc-dialog-div .e-sort-none::before {
  content: '\e666' !important;
}

.e-pivot-calc-dialog-div .e-sort-ascend::before {
  content: '\e667' !important;
}

.e-pivot-calc-dialog-div .e-sort-descend::before {
  content: '\e665' !important;
}

.e-pivotfieldlist-wrapper .e-field-list-back-icon::before {
  content: '\e977';
}

.e-pivotfieldlist-wrapper .e-sort::before {
  content: '\e840';
}

.e-pivotfieldlist-wrapper .e-pv-filter::before {
  content: '\e909';
}

.e-pivotfieldlist-wrapper .e-pv-filtered::before {
  color: #e3165b;
  content: '\ebb3';
}

.e-pivotfieldlist-wrapper .e-drag::before {
  content: '\e330';
}

.e-pivotfieldlist-wrapper .e-add-icon::before {
  content: '\e823';
}

.e-pivotfieldlist-wrapper .e-remove::before {
  content: '\e208';
}

.e-pivotfieldlist-wrapper .e-axis-rows::before {
  content: '\ea75';
}

.e-pivotfieldlist-wrapper .e-axis-columns::before {
  content: '\ea74';
}

.e-pivotfieldlist-wrapper .e-axis-values::before {
  content: '\ea76';
}

.e-pivotfieldlist-wrapper .e-axis-filters::before {
  content: '\e909';
}

.e-pivotfieldlist-wrapper .e-selected-option-icon::before {
  content: '\ea84';
}

.e-pivotfieldlist-wrapper .e-clear-filter-icon::before {
  content: '\ea82';
}

.e-pivotfieldlist-wrapper .e-dropdown-icon::before {
  content: '\e83d';
}

.e-pivotfieldlist-wrapper .e-measureGroupCDB-icon::before {
  content: '\ea76' !important;
}

.e-pivotfieldlist-wrapper .e-measure-icon::before {
  content: '\ea76' !important;
}

.e-pivotfieldlist-wrapper .e-folderCDB-icon::before {
  content: '\e60b' !important;
}

.e-pivotfieldlist-wrapper .e-folderCDB-open-icon::before {
  content: '\e65f' !important;
}

.e-pivotfieldlist-wrapper .e-dimensionCDB-icon::before {
  content: '\e65b' !important;
}

.e-pivotfieldlist-wrapper .e-kpiCDB-icon::before {
  content: '\e664' !important;
}

.e-pivotfieldlist-wrapper .e-kpiGoal-icon::before {
  content: '\e664' !important;
}

.e-pivotfieldlist-wrapper .e-kpiStatus-icon::before {
  content: '\e664' !important;
}

.e-pivotfieldlist-wrapper .e-kpiTrend-icon::before {
  content: '\e664' !important;
}

.e-pivotfieldlist-wrapper .e-kpiValue-icon::before {
  content: '\e664' !important;
}

.e-pivotfieldlist-wrapper .e-namedSetCDB-icon::before {
  content: '\e65d' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchyCDB-icon::before {
  content: '\e663' !important;
}

.e-pivotfieldlist-wrapper .e-attributeCDB-icon::before {
  content: '\e65c' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-0-icon::before {
  content: '\e65a' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-1-icon::before {
  content: '\e65a' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-2-icon::before {
  content: '\e662' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-3-icon::before {
  content: '\e65e' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-4-icon::before {
  content: '\e660' !important;
}

.e-pivotfieldlist-wrapper .e-hierarchy-level-5-icon::before {
  content: '\e661' !important;
}

.e-pivotfieldlist-wrapper .e-calcMemberGroupCDB::before {
  content: '\e729' !important;
}

.e-pivotfieldlist-wrapper .e-calc-measure-icon::before {
  content: '\ea76' !important;
}

.e-pivotfieldlist-wrapper .e-calc-dimension-icon::before {
  content: '\e65b' !important;
}

.e-pivotfieldlist-wrapper .e-sort-none::before {
  content: '\e666' !important;
}

.e-pivotfieldlist-wrapper .e-sort-ascend::before {
  content: '\e667' !important;
}

.e-pivotfieldlist-wrapper .e-sort-descend::before {
  content: '\e665' !important;
}

.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-edited.e-icons::before {
  content: '\e7a3';
}

.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-edit.e-icons::before {
  content: '\e891';
}

.e-pivotfieldlist-wrapper .e-pivot-calc-outer-div .e-pivot-accord .e-remove-report.e-icons::before {
  content: '\e706';
}

.e-pivotfieldlist-wrapper .e-pivot-button .e-edit::before {
  content: '\e891';
}

.e-pivotfieldlist-wrapper .e-sort-ascend-icon::before {
  content: '\e734';
}

.e-pivotfieldlist-wrapper .e-sort-descend-icon::before {
  content: '\e733';
}

/*! field-list theme */
.e-pivotfieldlist .e-disable {
  display: none;
}

.e-pivotfieldlist .e-hide {
  visibility: hidden;
}

.e-pivot-formatting-dialog {
  max-height: 480px !important;
  min-width: 628px !important;
  width: 628px !important;
}

.e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td {
  padding-bottom: 10px;
}

.e-pivot-formatting-dialog .e-format-delete-icon {
  font-weight: normal;
}

.e-pivot-formatting-dialog .e-format-delete-icon:hover {
  font-weight: 600;
}

.e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td {
  padding-bottom: 14px;
  padding-right: 18px;
}

.e-pivot-formatting-dialog .e-format-value-span {
  vertical-align: bottom;
}

.e-pivot-formatting-dialog .e-format-value1,
.e-pivot-formatting-dialog .e-format-value2,
.e-pivot-formatting-dialog .e-format-value-span {
  margin-right: 10px;
}

.e-pivot-formatting-dialog .e-format-font-color-picker {
  margin-left: 0;
  margin-right: 18px;
}

.e-pivot-formatting-dialog .e-format-inner-div {
  padding-left: 14px;
  padding-top: 14px;
}

.e-pivot-formatting-dialog .e-format-outer-div {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 14px;
}

.e-pivot-formatting-dialog .e-format-condition-button {
  float: left;
}

.e-pivot-formatting-dialog .e-format-delete-button {
  background-color: transparent;
  border-color: transparent;
  float: right;
  padding-right: 12px;
  padding-top: 12px;
}

.e-pivot-formatting-dialog .e-format-delete-button:hover,
.e-pivot-formatting-dialog .e-format-delete-button:focus,
.e-pivot-formatting-dialog .e-format-delete-button:active {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 transparent;
}

.e-pivot-formatting-dialog .e-format-delete-button:hover .e-format-delete-icon,
.e-pivot-formatting-dialog .e-format-delete-button:focus .e-format-delete-icon,
.e-pivot-formatting-dialog .e-format-delete-button:active .e-format-delete-icon {
  font-weight: 600;
}

.e-pivot-formatting-dialog .e-format-delete-icon {
  font-size: 10px;
}

.e-pivot-formatting-dialog .e-format-label,
.e-pivot-formatting-dialog .e-format-value-label {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.87;
}

.e-pivot-formatting-dialog .e-format-value-preview {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 28px;
  margin: 0 !important;
  padding-left: 6px;
  padding-top: 6px;
  width: 80px;
}

.e-pivot-formatting-dialog .e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn .e-selected-color {
  background: none;
  margin: 0 2px;
  width: 14px;
}

.e-pivot-formatting-dialog .e-split-preview,
.e-pivot-formatting-dialog .e-tip-transparent {
  border: 1px solid #e0e0e0;
  border-radius: 0 !important;
  height: 40%;
  left: -3px;
  position: absolute;
  top: 16px;
  width: 150%;
}

.e-pivot-formatting-dialog.e-device {
  min-width: 320px !important;
  width: 100%  !important;
}

.e-pivot-formatting-dialog.e-device .e-format-value1,
.e-pivot-formatting-dialog.e-device .e-format-value2,
.e-pivot-formatting-dialog.e-device .e-format-value-span {
  margin-right: 2px;
}

.e-pivot-formatting-dialog.e-device .e-format-color-picker {
  width: 33%;
}

.e-pivot-formatting-dialog.e-device .e-format-delete-button {
  font-size: 12px;
  padding-right: 16px;
  padding-top: 16px;
}

.e-pivot-formatting-dialog.e-device .e-format-apply-button,
.e-pivot-formatting-dialog.e-device .e-format-cancel-button {
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker {
  margin-left: 18px;
  margin-right: 0;
}

.e-pivot-formatting-dialog.e-rtl .e-format-condition-button {
  float: right;
}

.e-pivot-formatting-dialog.e-rtl .e-format-condition-button .e-add-icon {
  margin-left: auto;
}

.e-pivot-formatting-dialog.e-rtl .e-format-inner-div {
  padding-left: 0;
  padding-right: 14px;
}

.e-pivot-formatting-dialog.e-rtl .e-format-delete-button {
  float: left;
  padding-left: 12px;
}

.e-bigger.e-pivot-formatting-dialog,
.e-bigger .e-pivot-formatting-dialog,
.e-bigger.e-pivot-formatting-dialog,
.e-bigger .e-pivot-formatting-dialog {
  min-width: 680px !important;
  width: 680px !important;
}

.e-bigger.e-pivot-formatting-dialog .e-format-label,
.e-bigger.e-pivot-formatting-dialog .e-format-value-label,
.e-bigger .e-pivot-formatting-dialog .e-format-label,
.e-bigger .e-pivot-formatting-dialog .e-format-value-label,
.e-bigger.e-pivot-formatting-dialog .e-format-label,
.e-bigger.e-pivot-formatting-dialog .e-format-value-label,
.e-bigger .e-pivot-formatting-dialog .e-format-label,
.e-bigger .e-pivot-formatting-dialog .e-format-value-label {
  font-size: 14px;
}

.e-bigger.e-pivot-formatting-dialog .e-format-value1, .e-bigger.e-pivot-formatting-dialog .e-format-value2,
.e-bigger .e-pivot-formatting-dialog .e-format-value1,
.e-bigger .e-pivot-formatting-dialog .e-format-value2,
.e-bigger.e-pivot-formatting-dialog .e-format-value1,
.e-bigger.e-pivot-formatting-dialog .e-format-value2,
.e-bigger .e-pivot-formatting-dialog .e-format-value1,
.e-bigger .e-pivot-formatting-dialog .e-format-value2 {
  margin-bottom: 3px !important;
}

.e-bigger.e-pivot-formatting-dialog .e-format-inner-div,
.e-bigger .e-pivot-formatting-dialog .e-format-inner-div,
.e-bigger.e-pivot-formatting-dialog .e-format-inner-div,
.e-bigger .e-pivot-formatting-dialog .e-format-inner-div {
  padding-left: 16px;
  padding-top: 16px;
}

.e-bigger.e-pivot-formatting-dialog .e-format-outer-div,
.e-bigger .e-pivot-formatting-dialog .e-format-outer-div,
.e-bigger.e-pivot-formatting-dialog .e-format-outer-div,
.e-bigger .e-pivot-formatting-dialog .e-format-outer-div {
  margin-bottom: 16px;
}

.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td,
.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(odd) td {
  padding-bottom: 12px;
}

.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td,
.e-bigger.e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td,
.e-bigger .e-pivot-formatting-dialog .e-format-table tr:nth-child(even) td {
  padding-bottom: 16px;
  padding-right: 20px;
}

.e-bigger.e-pivot-formatting-dialog .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog .e-format-font-color-picker,
.e-bigger.e-pivot-formatting-dialog .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog .e-format-font-color-picker {
  margin-left: 0;
  margin-right: 20px;
}

.e-bigger.e-pivot-formatting-dialog.e-device,
.e-bigger .e-pivot-formatting-dialog.e-device,
.e-bigger.e-pivot-formatting-dialog.e-device,
.e-bigger .e-pivot-formatting-dialog.e-device {
  min-width: 320px !important;
  width: 100%  !important;
}

.e-bigger.e-pivot-formatting-dialog.e-device .e-format-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-color-picker,
.e-bigger.e-pivot-formatting-dialog.e-device .e-format-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-color-picker {
  width: 33%;
}

.e-bigger.e-pivot-formatting-dialog.e-device .e-format-delete-button,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-delete-button,
.e-bigger.e-pivot-formatting-dialog.e-device .e-format-delete-button,
.e-bigger .e-pivot-formatting-dialog.e-device .e-format-delete-button {
  font-size: 12px;
  padding-right: 16px;
  padding-top: 16px;
}

.e-bigger.e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker,
.e-bigger.e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker,
.e-bigger .e-pivot-formatting-dialog.e-rtl .e-format-font-color-picker {
  margin-left: 20px;
  margin-right: 0;
}

.e-pivot-calc-dialog-div {
  max-height: 550px !important;
  min-width: 290px;
}

.e-pivot-calc-dialog-div .e-dlg-header-content,
.e-pivot-calc-dialog-div .e-footer-content {
  border: 0;
}

.e-pivot-calc-dialog-div .e-dlg-content {
  padding-bottom: 18px;
}

.e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-pivot-calc-dialog-div .e-olap-format-title,
.e-pivot-calc-dialog-div .e-olap-member-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  margin-top: 15px;
  overflow: hidden;
  padding: 4px 0;
  padding-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivot-calc-dialog-div .e-treeview .e-sibling {
  display: none;
}

.e-pivot-calc-dialog-div .e-treeview .e-list-selected {
  opacity: .5;
}

.e-pivot-calc-dialog-div .e-treeview ul {
  overflow: hidden;
}

.e-pivot-calc-dialog-div .e-treeview ul li {
  padding: 0;
}

.e-pivot-calc-dialog-div .e-treeview ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-pivot-calc-dialog-div .e-treeview ul li .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}

.e-pivot-calc-dialog-div .e-treeview ul .e-fullrow {
  cursor: default;
}

.e-pivot-calc-dialog-div .e-treeview ul .e-list-item .e-text-content {
  height: 36px;
  margin-left: -10px;
  padding: 0;
}

.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-icons {
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  opacity: 0.87;
  padding: 2px 4px 4px;
  pointer-events: auto;
  width: 24px;
}

.e-pivot-calc-dialog-div .e-treeview .e-list-item div.e-iconspace {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 24px;
  opacity: 0.87;
  padding: 2px 4px 4px;
  pointer-events: auto;
  width: 24px;
}

.e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons {
  color: #bdbdbd;
  cursor: move;
  display: inline-block;
  font-size: 14px;
  height: 24px;
  margin-right: 4px;
  margin-top: 4px;
  padding: 5px;
  pointer-events: auto;
  width: 24px;
}

.e-pivot-calc-dialog-div .e-dlg-header {
  font-size: 18px;
  font-weight: 500;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 74%;
}

.e-pivot-calc-dialog-div .e-contextmenu {
  background-color: #fff;
  border-radius: 4px;
}

.e-pivot-calc-dialog-div .e-contextmenu .e-menu-item {
  color: #000;
  font-size: 14px;
  height: 48px;
  opacity: .87;
}

.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-icons {
  font-size: 14px;
  height: 24px;
  margin-bottom: 10px;
  padding: 5px;
  width: 24px;
}

.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-edit.e-icons,
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-edited.e-icons,
.e-pivot-calc-dialog-div.e-rtl .e-treeview .e-list-item div.e-remove-report.e-icons {
  margin-right: 0;
  transform: rotate(0deg);
}

.e-pivot-calc-dialog-div.e-rtl .e-treeview ul {
  margin-right: 0;
}

.e-pivot-calc-dialog-div.e-rtl .e-treeview ul li {
  margin-left: 0;
  margin-right: -40px;
}

.e-pivot-calc-dialog-div.e-rtl .e-treeview ul li .e-text-content {
  padding: 0 24px 0 0;
}

.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivot-calc-dialog-div .e-pivot-calc-input.e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivot-calc-dialog-div .e-pivot-formula {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  height: 70px;
  margin-top: 5px;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}

.e-pivot-calc-dialog-div .e-pivot-formula.e-copy-drop {
  cursor: copy;
}

.e-pivot-calc-dialog-div .e-list-parent .e-list-text {
  margin-bottom: 8px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
}

.e-pivot-calc-dialog-div .e-pivot-treeview-outer {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 120px;
  margin-top: 5px;
  overflow: auto;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div {
  min-width: 575px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div {
  display: -ms-flexbox;
  display: flex;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div {
  height: 430px;
  width: 50%;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer {
  height: 100%;
  margin-top: 0;
  overflow: hidden;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div {
  display: inline-block;
  height: 400px;
  overflow: auto;
  width: 100%;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview {
  display: inline-table;
  height: 100%;
  width: 100%;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-fullrow {
  cursor: move;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-text {
  margin-bottom: 0;
  overflow: unset;
  width: 100%;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-calcfieldmember .e-list-text {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-item .e-text-content {
  height: 30px;
  margin: 0;
  padding: 0 0 0 24px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-remove-report.e-list-icon {
  cursor: pointer;
  font-size: 13px;
  margin-top: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon {
  cursor: auto;
  font-size: 14px;
  width: auto;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon.e-edit {
  cursor: pointer;
  padding: 7px !important;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon.e-edited {
  cursor: pointer;
  padding: 7px !important;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-item div.e-icons {
  padding: 0;
  height: auto;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul li {
  margin-left: 0;
  margin-right: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul .e-list-item .e-text-content {
  padding: 0 24px 0 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview.e-rtl ul li {
  margin-left: 0;
  margin-right: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title {
  color: rgba(0, 0, 0, 0.54);
  -ms-flex: auto;
      flex: auto;
  margin-top: 0;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper .e-info {
  padding: 7px !important;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper .e-info:hover {
  color: #e3165b;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div {
  -ms-flex: auto;
      flex: auto;
  margin-left: 18px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-pivot-formula {
  height: 100px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-olap-calc-custom-format-div {
  margin-top: 15px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-pivot-formula-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-pivot-field-name-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-olap-hierarchy-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-olap-format-title,
.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-olap-member-title {
  padding-left: 0;
  padding-right: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div .e-pivot-field-name-title {
  margin-top: 0;
  padding-top: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-dialog {
  max-height: 700px !important;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-dialog .e-calc-clear-btn {
  float: left;
  margin: 0;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-olap-calculated-div {
  margin-left: 0;
  margin-right: 18px;
}

.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl.e-dialog .e-calc-clear-btn {
  float: right;
}

.e-bigger.e-pivot-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div,
.e-bigger.e-pivot-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div {
  max-height: 580px !important;
  min-width: 310px;
}

.e-bigger.e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-format-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-member-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-format-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-member-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-format-title,
.e-bigger.e-pivot-calc-dialog-div .e-olap-member-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-field-name-title,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-hierarchy-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-format-title,
.e-bigger .e-pivot-calc-dialog-div .e-olap-member-title {
  font-size: 14px;
  padding: 6px 0;
  padding-bottom: 0;
}

.e-bigger.e-pivot-calc-dialog-div .e-treeview ul li,
.e-bigger .e-pivot-calc-dialog-div .e-treeview ul li,
.e-bigger.e-pivot-calc-dialog-div .e-treeview ul li,
.e-bigger .e-pivot-calc-dialog-div .e-treeview ul li {
  padding: 2px 0;
}

.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons,
.e-bigger.e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons,
.e-bigger .e-pivot-calc-dialog-div .e-treeview .e-list-item span.e-icons {
  font-size: 16px;
}

.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula,
.e-bigger.e-pivot-calc-dialog-div .e-pivot-formula,
.e-bigger .e-pivot-calc-dialog-div .e-pivot-formula {
  font-size: 13px;
}

.e-bigger.e-pivot-calc-dialog-div .e-footer-content,
.e-bigger .e-pivot-calc-dialog-div .e-footer-content,
.e-bigger.e-pivot-calc-dialog-div .e-footer-content,
.e-bigger .e-pivot-calc-dialog-div .e-footer-content {
  padding-right: 8px;
  padding-top: 8px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div {
  max-height: 700px !important;
  min-width: 580px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div {
  height: 501px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-treeview-outer-div {
  height: 470px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title {
  padding-bottom: 6px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-pivot-all-field-title-wrapper {
  font-size: 14px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-field-tree-div .e-pivot-treeview-outer .e-treeview ul .e-list-icon {
  font-size: 16px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div .e-pivot-calc-outer-div .e-olap-calculated-div {
  margin-left: 24px;
}

.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger.e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-olap-calculated-div,
.e-bigger .e-pivot-calc-dialog-div.e-olap-calc-dialog-div.e-rtl .e-pivot-calc-outer-div .e-olap-calculated-div {
  margin-left: 0;
  margin-right: 24px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog,
.e-pivotfieldlist-wrapper .e-member-editor-dialog,
.e-pivotfieldlist-wrapper .e-value-field-settings {
  border-radius: 4px;
  width: auto;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-footer-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-footer-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-footer-content {
  border-radius: 4px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content {
  border: 0;
  font-weight: normal;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-footer-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-footer-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-footer-content {
  border-top: 1px solid #e0e0e0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-footer-content .e-clear-filter-button,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-footer-content .e-clear-filter-button,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-footer-content .e-clear-filter-button {
  float: left;
  margin: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-footer-content .e-clear-filter-button.e-disable,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-footer-content .e-clear-filter-button.e-disable,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-footer-content .e-clear-filter-button.e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content {
  overflow: hidden;
  padding: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-editor-label-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-editor-label-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-editor-label-wrapper {
  background-color: #f5f5f5;
  opacity: 56%;
  padding: 14px 10px 16px 20px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-editor-label-wrapper .e-editor-label,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-editor-label-wrapper .e-editor-label,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-editor-label-wrapper .e-editor-label {
  color: #000;
  font: 500 13px Roboto;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content {
  padding: 0 20px 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  font-size: 13px;
  font-weight: 500;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title {
  -ms-flex: none;
      flex: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  -ms-flex: auto;
      flex: auto;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper {
  padding-top: 20px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text {
  font-size: 13px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper {
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort {
  box-shadow: none;
  margin-left: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 16px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort .e-member-sort:focus.e-active {
  background-color: rgba(184, 184, 184, 0.9584);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-prompt,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-prompt,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-prompt {
  padding: 25px 20px;
  text-align: center;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div {
  display: inline-block;
  height: 160px;
  max-width: 400px;
  overflow: auto;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container {
  display: inline-table;
  height: 100%;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-rtl ul .e-list-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-rtl ul .e-list-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul {
  margin-left: -35px;
  overflow: hidden;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-list-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-list-icon {
  margin: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-select-all-wrapper ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-member-editor-container ul li .e-kpiValue-icon {
  margin-left: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-disable,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-disable,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-member-editor-wrapper .e-disable {
  display: none !important;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper {
  border: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon {
  height: 14px;
  min-width: 14px;
  width: 14px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-text-wrap .e-tab-icon::before {
  font-size: 14px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter {
  padding-top: 20px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div {
  height: 147px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter {
  height: 248px;
  overflow: auto;
  padding: 20px;
  padding-bottom: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div {
  font-size: 13px;
  font-weight: 500;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div {
  padding-top: 20px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div {
  padding: 8px 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-disable,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-disable,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-disable,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-disable,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-disable,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-empty-field::-webkit-input-placeholder,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-empty-field::-webkit-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-empty-field:-moz-placeholder,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-empty-field:-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-empty-field::-moz-placeholder,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-empty-field::-moz-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-empty-field:-ms-input-placeholder,
.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-empty-field:-ms-input-placeholder {
  color: #f44336 !important;
  font-weight: 600 !important;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-excel-filter .e-editor-label-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-excel-filter .e-editor-label-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-excel-filter .e-editor-label-wrapper {
  opacity: 56%;
  padding: 14px 10px 9px 20px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-rtl .e-member-editor-wrapper ul {
  margin-left: 0;
  margin-right: -35px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-rtl .e-footer-content .e-clear-filter-button,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-footer-content .e-clear-filter-button,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-rtl .e-footer-content .e-clear-filter-button {
  float: right;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-select-all-wrapper ul,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-member-editor-container ul {
  margin-left: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop {
  margin: 0 5px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop .e-caret {
  display: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog .e-member-editor-wrapper .e-editor-search-wrapper .e-level-drop li .e-disabled {
  opacity: .5;
  pointer-events: none;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper .e-editor-search-wrapper .e-filter-sort {
  margin-left: 0;
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul {
  margin-right: 0;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-value-field-settings.e-olap-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper .e-member-editor-dialog {
  min-width: 320px;
  max-width: 400px;
}

.e-pivotfieldlist-wrapper .e-value-field-settings {
  max-width: 400px;
  min-width: 300px;
}

.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content {
  overflow: auto;
}

.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div {
  height: 200px;
}

.e-field-list-tree.e-drag-item.e-treeview,
.e-pivot-calc.e-drag-item.e-treeview {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  height: 28px;
  min-width: 100px;
  padding: 5px 10px;
}

.e-field-list-tree.e-drag-item.e-treeview .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content {
  padding: 0;
  text-align: center;
  width: 100%;
}

.e-field-list-tree.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 1em;
  width: 100%;
}

.e-field-list-tree.e-drag-item.e-treeview .e-text-content > .e-icons,
.e-field-list-tree.e-drag-item.e-treeview .e-checkbox-wrapper,
.e-field-list-tree.e-drag-item.e-treeview .e-list-icon,
.e-pivot-calc.e-drag-item.e-treeview .e-text-content > .e-icons,
.e-pivot-calc.e-drag-item.e-treeview .e-checkbox-wrapper,
.e-pivot-calc.e-drag-item.e-treeview .e-list-icon {
  display: none;
}

.e-field-list-tree.e-drag-item.e-treeview.e-rtl .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-rtl .e-text-content {
  padding-right: 0;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-text-content {
  padding-left: 24px;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-text-content .e-list-text,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-text-content .e-list-text {
  padding: 0;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop {
  display: inline-block !important;
  line-height: 0em;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before {
  float: left;
  font-size: 14px;
  padding: 5px 0;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content {
  padding-left: 0;
  padding-right: 24px;
}

.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content .e-icons,
.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict.e-rtl .e-text-content .e-icons {
  transform: rotateY(180deg);
}

.e-bigger .e-field-list-tree.e-drag-item.e-treeview,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview,
.e-bigger.e-pivot-calc.e-drag-item.e-treeview,
.e-bigger .e-field-list-tree.e-drag-item.e-treeview,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview
.e-bigger.e-pivot-calc.e-drag-item.e-treeview {
  height: 36px;
  padding: 6px 12px;
}

.e-bigger .e-field-list-tree.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger.e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger .e-field-list-tree.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview
.e-bigger.e-pivot-calc.e-drag-item.e-treeview .e-text-content .e-list-text {
  line-height: 1.5em;
}

.e-bigger .e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger .e-field-list-tree.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger .e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before,
.e-bigger.e-field-list-tree.e-drag-item.e-treeview
.e-bigger.e-pivot-calc.e-drag-item.e-treeview.e-drag-restrict .e-no-drop::before {
  padding: 4px;
}

.e-button-drag-clone {
  background-color: #fff !important;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  height: 28px;
  line-height: 1.0em;
  min-width: 100px;
  overflow: hidden;
  padding: 5px 10px;
  text-align: center;
  width: auto;
  z-index: 100000;
}

.e-button-drag-clone .e-text-content {
  width: 100%;
}

.e-bigger .e-button-drag-clone,
.e-bigger.e-button-drag-clone,
.e-bigger .e-button-drag-clone,
.e-bigger.e-button-drag-clone {
  height: 36px !important;
  line-height: 1.5em;
  padding: 6px 12px;
}

.e-pivot-error-dialog {
  max-width: 400px;
  width: auto;
}

.e-pivot-error-dialog .e-dlg-header-content,
.e-pivot-error-dialog .e-footer-content {
  border: 0;
}

.e-pivot-error-dialog .e-dlg-header {
  font-weight: normal;
  opacity: 0.87;
}

.e-pivot-error-dialog .e-dlg-content {
  font-weight: normal;
  opacity: 0.54;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-member-editor-wrapper .e-member-editor-container-outer-div {
  height: 125px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-ascend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-editor-search-wrapper .e-filter-sort .e-member-sort .e-sort-descend-icon {
  font-size: 18px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-member-editor-wrapper ul li .e-kpiValue-icon {
  margin-left: 10px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-rtl .e-member-editor-wrapper ul li .e-kpiValue-icon {
  margin-left: 10px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-header-content .e-clear-filter-button.e-small,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-header-content .e-clear-filter-button.e-small {
  font-size: 14px;
  top: 0;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 6px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-filter-tab-wrapper.e-rtl .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 6px 0 0;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-excelfilter .e-member-editor-container-outer-div {
  height: 100px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter {
  height: 220px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-text-div {
  font-size: 14px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-separator-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-1,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-filter-option-wrapper-2,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-separator-div {
  padding-top: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog .e-dlg-content .e-value-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-label-filter .e-between-text-div,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-dlg-content .e-value-filter .e-between-text-div {
  padding: 10px 0;
}

.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-excel-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-excel-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings.e-excel-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-excel-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-member-editor-dialog.e-excel-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-adaptive-field-list-dialog.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-member-editor-dialog.e-excel-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings.e-excel-filter {
  min-width: 350px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content {
  padding: 0 20px 10px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-title,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-field-name-text-wrapper .e-field-name-content {
  font-size: 14px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper {
  padding-top: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger .e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-caption-input-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-type-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-field-option-wrapper .e-caption-input-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-field-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-base-item-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-type-option-text,
.e-bigger.e-pivotfieldlist-wrapper .e-value-field-settings .e-value-field-div-content .e-field-option-wrapper .e-base-item-option-wrapper .e-caption-input-text {
  font-size: 14px;
}

/*! field-list theme */
.e-pivotfieldlist .e-member-editor-dialog,
.e-pivotfieldlist .e-olap-editor-dialog {
  visibility: hidden;
}

.e-pivotfieldlist .e-select-table {
  background-color: #fafafa;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  font-size: 17px;
  height: 31px;
  max-height: 31px;
  max-width: 31px;
  min-height: 31px;
  min-width: 31px;
  padding: 8px;
  position: relative;
  width: 31px;
  z-index: 1000;
}

.e-pivotfieldlist .e-select-table.e-fieldlist-left {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.12);
}

.e-pivotfieldlist .e-select-table:focus, .e-pivotfieldlist .e-select-table:active {
  background-color: #e0e0e0;
}

.e-pivotfieldlist .e-select-table:hover {
  background-color: #e0e0e0;
}

.e-rtl .e-select-table {
  padding: 8px 5px;
}

.e-bigger .e-pivotfieldlist .e-select-table,
.e-bigger.e-pivotfieldlist .e-select-table,
.e-bigger .e-pivotfieldlist .e-select-table,
.e-bigger.e-pivotfieldlist .e-select-table {
  font-size: 20px;
  height: 36px;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
}

.e-pivotfieldlist-wrapper {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 12px;
  max-width: 700px;
  min-width: 300px;
}

.e-pivotfieldlist-wrapper .e-layout-footer {
  padding: 10px 2px 2px;
  text-align: right;
}

.e-pivotfieldlist-wrapper .e-layout-footer .e-defer-apply-button {
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper .e-layout-footer .e-cancel-btn,
.e-pivotfieldlist-wrapper .e-layout-footer .e-defer-apply-button,
.e-pivotfieldlist-wrapper .e-layout-footer .e-checkbox-layout {
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivotfieldlist-wrapper .e-layout-footer .e-checkbox-layout .e-label {
  white-space: nowrap;
}

.e-pivotfieldlist-wrapper .e-layout-footer .e-checkbox-layout {
  max-width: 45%;
}

.e-pivotfieldlist-wrapper .e-checkbox-layout {
  float: left;
  margin-top: 6px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-layout-footer {
  text-align: left;
}

.e-pivotfieldlist-wrapper.e-rtl .e-layout-footer .e-defer-apply-button {
  margin-left: 10px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-checkbox-layout {
  float: right;
}

.e-pivotfieldlist-wrapper.e-dialog {
  max-height: 550px !important;
}

.e-pivotfieldlist-wrapper.e-dialog .e-layout-footer {
  padding: 0;
}

.e-pivotfieldlist-wrapper.e-dialog .e-checkbox-layout {
  margin-left: 10px;
}

.e-pivotfieldlist-wrapper.e-dialog .e-button-layout {
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper .e-dlg-header-content,
.e-pivotfieldlist-wrapper .e-footer-content {
  border: 0;
}

.e-pivotfieldlist-wrapper .e-field-list-title {
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  min-height: 25px;
  padding: 0 2px 20px;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-title .e-title-content {
  -ms-flex: auto;
      flex: auto;
}

.e-pivotfieldlist-wrapper .e-title-header {
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-title-header .e-title-content {
  -ms-flex: auto;
      flex: auto;
}

.e-pivotfieldlist-wrapper .e-field-list-container {
  display: -ms-flexbox;
  display: flex;
  height: 342px;
  padding: 1px;
  position: relative;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  width: 30%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list-tree .e-sibling {
  display: none;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list {
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: normal;
  height: 28px;
  overflow: hidden;
  padding: 7px 0 0 15px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-all;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-field-header {
  -ms-flex: auto;
      flex: auto;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 14px;
  height: 20px;
  padding: 7px 0 0 4px;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none.e-selected::before,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend.e-selected::before,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend.e-selected::before {
  color: #e3165b;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend:hover {
  font-weight: bold;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none:focus,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend:focus,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend:focus {
  font-weight: bold;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list-tree-outer-div {
  display: inline-block;
  height: 310px;
  overflow: auto;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list {
  display: inline-table;
  height: 100%;
  padding-left: 2px;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul {
  overflow: hidden;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li {
  margin-left: -40px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-fullrow {
  cursor: default;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-drag {
  color: #bdbdbd;
  cursor: move;
  display: inline-block;
  font-size: 14px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 6px;
  pointer-events: all;
  text-align: center;
  vertical-align: bottom;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-drag.e-disable-drag {
  cursor: not-allowed;
  opacity: .4;
  visibility: hidden;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-level-members,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-measure-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-kpiValue-icon {
  margin-left: 10px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-list-selected {
  opacity: .5;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-selected-node {
  font-weight: 500;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree ul li {
  margin-left: 0;
  margin-right: 0;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon {
  font-size: 14px;
  height: auto;
  width: auto;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-treeview ul:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list.e-olap-field-list-tree.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table.e-olap-field-table {
  width: 40%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 70%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 8px;
  width: 50%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  fill: #fff;
  font-weight: normal;
  min-height: 165px;
  position: relative;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  padding: 2px 15px 5px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper {
  height: 20px;
  padding-top: 4px;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-header,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-header,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-header,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-header {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  line-height: 1.5em;
  margin-top: 2px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content {
  cursor: default;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content.e-drag-restrict,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content.e-drag-restrict {
  cursor: no-drop;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator-last,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator {
  display: block;
  pointer-events: none;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator-last.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-drop-indicator.e-drop-hover {
  border-top: 2px dashed #9e9e9e;
  margin-top: -2px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div.e-first-btn .e-drop-indicator.e-drop-hover {
  position: absolute;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button {
  background-color: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  height: 32px;
  line-height: 1em;
  padding: 9px 12px;
  text-transform: none;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-list-selected {
  color: #000;
  opacity: .5;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:hover {
  background-color: #eee;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:hover .e-content {
  background-color: transparent;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active {
  background-color: #e0e0e0;
  color: #000;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-content, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-content {
  background-color: transparent;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:focus .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button:active .e-edit {
  color: #000;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content {
  background-color: transparent;
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  height: 16px;
  line-height: 1em;
  overflow: hidden;
  padding: 0 8px;
  text-align: left;
  text-overflow: ellipsis;
  width: 70%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable {
  height: 20px;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag {
  color: #bdbdbd;
  cursor: move;
  font-size: 14px;
  height: 20px;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag.e-disable-drag {
  cursor: not-allowed;
  opacity: .4;
  visibility: hidden;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 20px;
  padding-left: 4px;
  width: 20px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 14px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-remove {
  display: none;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-content {
  text-align: right;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit {
  padding-left: 0;
  padding-right: 4px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-sort,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-sort {
  height: -webkit-fit-content;
  height: fit-content;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-sort.e-descend,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-sort.e-descend,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-sort.e-descend,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-sort.e-descend {
  transform: rotateX(180deg);
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content {
  height: 133px;
  overflow: auto;
  scroll-behavior: smooth;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-draggable-prompt,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-draggable-prompt,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-draggable-prompt,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-draggable-prompt {
  bottom: 32%;
  color: #e0e0e0;
  font-size: 12px;
  height: 25px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 100%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-draggable-prompt.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-draggable-prompt.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-draggable-prompt.e-disable,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-draggable-prompt.e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 8px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table.e-olap-axis-table {
  width: 60%;
}

.e-pivotfieldlist-wrapper .e-calculated-field {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  max-width: 160px;
  min-width: 145px;
}

.e-pivotfieldlist-wrapper .e-calculated-field.e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list {
  padding-left: 0;
  padding-right: 2px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li {
  margin-left: 0;
  margin-right: -40px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-list-text {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-drag {
  margin-left: 6px !important;
  margin-right: 0 !important;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-calc-measure-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-calc-dimension-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-attributeCDB-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-hierarchyCDB-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-level-members,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-namedSetCDB-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-measure-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-kpiGoal-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-kpiStatus-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-kpiTrend-icon,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list ul li .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header {
  padding-left: 0;
  padding-right: 15px;
  text-align: right;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper {
  padding-left: 15px;
  padding-right: 0;
}

.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-none,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-descend {
  padding: 7px 4px 0 0;
}

.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields,
.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields {
  padding-left: 0;
  padding-right: 8px;
}

.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-header,
.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-header,
.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-header,
.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-header {
  float: right;
}

.e-pivotfieldlist-wrapper.e-static {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  max-height: 700px;
  padding: 10px;
  position: relative;
}

.e-pivotfieldlist-wrapper.e-static .e-title-content {
  color: #000;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-field-table {
  height: 210px;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-field-table .e-field-list-tree-outer-div {
  height: 175px;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-div {
  display: none;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  height: 25px;
  margin-top: 5px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table {
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields {
  padding: 0;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 8px;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 8px;
}

.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper {
  padding-top: 4px;
}

.e-pivotfieldlist-wrapper.e-static.e-rtl .e-center-title {
  text-align: right;
}

.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-left-axis-fields {
  padding-left: 10px;
}

.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-right-axis-fields {
  padding: 0;
}

.e-pivotfieldlist-wrapper.e-device {
  height: 100%;
  max-height: 100% !important;
  max-width: 100% !important;
  min-height: 500px;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-dlg-header-content {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  height: 48px;
  padding-bottom: 9px;
  padding-top: 9px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-field-list-dialog .e-dlg-header-content,
.e-pivotfieldlist-wrapper.e-device .e-member-editor-dialog .e-dlg-header-content {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.e-pivotfieldlist-wrapper.e-device .e-title-mobile-header {
  display: -ms-flexbox;
  display: flex;
}

.e-pivotfieldlist-wrapper.e-device .e-title-mobile-content {
  margin-top: 0;
}

.e-pivotfieldlist-wrapper.e-device .e-field-list-back-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  height: 24px;
  margin-left: 0;
  margin-right: 12px;
  padding-top: 7px;
  text-align: center;
  width: 24px;
}

.e-pivotfieldlist-wrapper.e-device .e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container {
  height: 100%;
  position: relative;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-tab-header {
  background-color: #f5f5f5;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-tab-header .e-toolbar-item .e-tab-wrap {
  border: 0;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content {
  display: initial;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values {
  display: inline-block;
  height: 100%;
  min-height: 250px;
  padding-top: 20px;
  position: relative;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-axis-content {
  overflow: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div {
  display: -ms-grid;
  display: grid;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-drop-indicator,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-drop-indicator {
  display: none;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  border-width: 1px 0;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  line-height: normal;
  padding: 9px 16px;
  text-transform: none;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-list-selected,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-list-selected {
  opacity: .5;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-content {
  -ms-flex: auto;
      flex: auto;
  font-size: 14px;
  height: 100%;
  margin-top: -2px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 70%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-drag,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-drag {
  display: none;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 24px;
  padding-left: 10px;
  width: 24px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon:hover,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-edit.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filter.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-pv-filtered.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-dropdown-icon.e-disable,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-edit.e-disable {
  display: none;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort {
  height: -webkit-fit-content;
  height: fit-content;
  transform: rotateX(0deg);
  transition: transform 200ms ease-in-out;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-sort.e-descend,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-sort.e-descend {
  transform: rotateX(180deg);
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button .e-remove {
  display: inline-block;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-content {
  text-align: right;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-sort,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filter,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-pv-filtered,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-remove,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-dropdown-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-pvt-btn-div .e-pivot-button.e-rtl .e-edit {
  padding-left: 0;
  padding-right: 10px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-axis-content {
  height: 100%;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-filters .e-draggable-prompt,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-rows .e-draggable-prompt,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-columns .e-draggable-prompt,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-field-list-values .e-draggable-prompt {
  border: 1px dashed #f5f5f5;
  color: #f5f5f5;
  font-size: 14px;
  height: 200px;
  left: 15%;
  line-height: 200px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 25%;
  vertical-align: middle;
  width: 70%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-formula {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  padding: 2px 3px;
  resize: none;
  width: 90%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-calc-input-div,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-member-options,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-calc-custom-format-div,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-olap-calc-custom-format-div {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 90% !important;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion {
  margin-top: 20px;
  overflow: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-acrdn-header-icon {
  display: table;
  font-size: 12px;
  height: 36px;
  min-height: 36px;
  min-width: 28px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 0;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-acrdn-header-icon .e-list-icon {
  cursor: pointer;
  display: table-cell;
  padding-right: 6px;
  text-align: center;
  vertical-align: middle;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion .e-icons.e-frame {
  margin-right: 12px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion.e-rtl .e-acrdn-header-icon {
  left: 16px;
  right: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-accordion.e-rtl .e-icons.e-frame {
  margin-left: 12px !important;
  margin-right: 0 !important;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-radio-wrapper {
  margin-bottom: 24px;
  margin-right: 20px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-radio-wrapper .e-label {
  color: #000;
  font-family: Roboto-Reguar;
  font-size: 14px;
  opacity: .87;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-ok-button,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-add-button,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-cancel-button {
  float: right;
  margin-right: 20px;
  margin-top: 32px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-ok-button {
  bottom: 0;
  position: sticky;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord {
  max-height: 290px;
  overflow: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-calcfieldmember .e-list-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 53%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon {
  display: table;
  height: 30px;
  min-height: 30px;
  min-width: 24px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 0;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon .e-list-edit-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord ul .e-list-header-icon .e-remove-report.e-icons {
  color: rgba(0, 0, 0, 0.54);
  display: table-cell;
  padding: 5px;
  pointer-events: all;
  text-align: center;
  vertical-align: middle;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord.e-rtl ul .e-list-header-icon {
  left: 16px;
  right: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-pivot-accord.e-rtl ul .e-list-icon {
  transform: rotateX(0deg) rotateY(180deg);
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-checkbox-wrapper .e-icons {
  height: 18px;
  width: 18px;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div.e-olap-calc-outer-div {
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer {
  bottom: 20px;
  display: inline-block;
  position: absolute;
  vertical-align: bottom;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content {
  display: inline-block;
  padding: 15px;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-field-list-btn,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-calculated-field-btn {
  float: right;
  height: 52px;
  pointer-events: all;
  width: 52px;
  z-index: 1000;
}

.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-field-list-btn .e-add-icon,
.e-pivotfieldlist-wrapper.e-device .e-adaptive-container .e-field-list-footer .e-field-list-footer-content .e-calculated-field-btn .e-add-icon {
  font-size: 18px;
  line-height: 50px;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer {
  background-color: transparent;
  bottom: 0;
  display: inline-block;
  padding: 15px;
  pointer-events: none;
  position: absolute;
  vertical-align: bottom;
  width: 100%;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-field-list-btn,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-calculated-field-btn,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button {
  float: right;
  height: 52px;
  pointer-events: all;
  width: 52px;
  z-index: 1000;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-field-list-btn .e-add-icon,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-calculated-field-btn .e-add-icon,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-cancel-btn .e-add-icon,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button .e-add-icon {
  font-size: 18px;
  line-height: 50px;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button {
  float: left;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-defer-apply-button,
.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-checkbox-layout {
  max-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pivotfieldlist-wrapper.e-device .e-footer-content.e-field-list-footer .e-checkbox-layout {
  max-width: 45%;
}

.e-pivotfieldlist-wrapper.e-device.e-static {
  border: 0;
  padding: 0;
}

.e-pivotfieldlist-wrapper.e-device.e-static .e-field-list-filters .e-axis-content,
.e-pivotfieldlist-wrapper.e-device.e-static .e-field-list-rows .e-axis-content,
.e-pivotfieldlist-wrapper.e-device.e-static .e-field-list-columns .e-axis-content,
.e-pivotfieldlist-wrapper.e-device.e-static .e-field-list-values .e-axis-content {
  max-height: 600px;
}

.e-pivotfieldlist-wrapper.e-device.e-rtl .e-field-list-back-icon {
  margin-left: 12px;
  margin-right: 0;
  transform: rotateY(180deg);
}

.e-pivotfieldlist-wrapper.e-device.e-rtl .e-field-list-footer .e-field-list-btn,
.e-pivotfieldlist-wrapper.e-device.e-rtl .e-field-list-footer .e-calculated-field-btn {
  float: left;
}

.e-pivotfieldlist-wrapper.e-device.e-rtl .e-field-list-footer .e-cancel-btn,
.e-pivotfieldlist-wrapper.e-device.e-rtl .e-field-list-footer .e-defer-apply-button {
  float: right;
}

.e-pivotfieldlist-wrapper.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-ok-button,
.e-pivotfieldlist-wrapper.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-add-button,
.e-pivotfieldlist-wrapper.e-device.e-rtl .e-pivot-calc-outer-div .e-pivot-cancel-button {
  float: left !important;
  margin-left: 20px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields {
  padding-left: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: normal;
  height: 30px;
  overflow: hidden;
  padding: 7px 0 0 20px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper {
  padding-right: 20px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-header-wrapper .e-sort-descend {
  font-size: 16px;
  height: 24px;
  padding: 7px 0 0 4px;
  width: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list {
  padding-left: 5px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list ul li,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list ul li,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list ul li,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list ul li {
  padding: 0;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-drag {
  font-size: 16px !important;
  height: 24px !important;
  margin-bottom: 3px !important;
  margin-right: 4px !important;
  width: 24px !important;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list .e-kpiValue-icon {
  margin-left: 10px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-field-list.e-olap-field-list-tree ul .e-list-icon {
  font-size: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields .e-field-list-values,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-field-table .e-right-axis-fields .e-field-list-values {
  margin-top: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper {
  height: 30px;
  padding: 0 20px 5px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper {
  height: 24px;
  padding-top: 7px;
  width: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-rows,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-columns,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-values,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper .e-axis-filters {
  font-size: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-header,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-header,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-header {
  font-size: 14px;
  line-height: 1.6em;
  margin-top: 4px;
  padding: 0 12px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button {
  font-size: 14px;
  height: 36px !important;
  padding: 9px 16px !important;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-content {
  font-size: 14px;
  line-height: 1.1em;
  padding: 0 10px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-draggable {
  height: 24px;
  width: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-edit {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 24px;
  width: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-sort {
  height: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger .e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag,
.e-bigger.e-pivotfieldlist-wrapper .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-content .e-pvt-btn-div .e-pivot-button .e-drag {
  font-size: 16px;
  width: 24px;
}

.e-bigger .e-pivotfieldlist-wrapper .e-calculated-field,
.e-bigger.e-pivotfieldlist-wrapper .e-calculated-field,
.e-bigger .e-pivotfieldlist-wrapper .e-calculated-field,
.e-bigger.e-pivotfieldlist-wrapper .e-calculated-field {
  font-size: 14px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static,
.e-bigger.e-pivotfieldlist-wrapper.e-static,
.e-bigger .e-pivotfieldlist-wrapper.e-static,
.e-bigger.e-pivotfieldlist-wrapper.e-static {
  max-height: 720px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-title,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-title,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-title,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-center-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields {
  padding-left: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
  margin-top: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns .e-axis-wrapper .e-axis-icon-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-static .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values .e-axis-wrapper .e-axis-icon-wrapper {
  padding-top: 7px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-left-axis-fields {
  padding-left: 16px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-static.e-rtl .e-axis-table .e-right-axis-fields {
  padding: 0 !important;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list {
  padding-left: 0;
  padding-right: 5px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-drag,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-drag,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-drag {
  margin-left: 4px !important;
  margin-right: 0 !important;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiValue-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-calc-dimension-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-attributeCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-hierarchyCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-level-members,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-namedSetCDB-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-measure-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiGoal-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiStatus-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiTrend-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-list .e-kpiValue-icon {
  margin-left: 0;
  margin-right: 10px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header {
  padding-right: 20px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper {
  padding-left: 20px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-descend,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-none,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-ascend,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-field-table .e-field-header-wrapper .e-sort-descend {
  padding: 7px 4px 0 0;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger .e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-filters .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-left-axis-fields .e-field-list-rows .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-columns .e-pivot-button .e-edit,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-sort,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filter,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-pv-filtered,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-remove,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-dropdown-icon,
.e-bigger.e-pivotfieldlist-wrapper.e-rtl .e-axis-table .e-right-axis-fields .e-field-list-values .e-pivot-button .e-edit {
  padding-left: 0;
  padding-right: 4px;
}
