.c5-tooltip {
  position: relative;
}

.c5-tooltip-wrapper {
  position: relative;

  min-width: 120px;
  min-height: 120px;
}

.c5-tooltip-trigger {
  display: inline-block;
}

.c5-tooltip-bubble {
  min-width: 120px;
  position: absolute;
  z-index: 10;
  transition: opacity 750ms;
}

.c5-tooltip-bubble::after {
  content: "";
  position: absolute;
}

.c5-tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.c5-tooltip-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #fff;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.c5-tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.c5-tooltip-bottom::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.c5-tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.c5-tooltip-left::after {
  border-left: 9px solid #fff;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.c5-tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.c5-tooltip-right::after {
  border-right: 9px solid #fff;
  border-top: 9px solid transparent;
  border-top: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.c5-tooltip-message {
  background: #fff;
  border-radius: 3px;
  color: #000;
  font-size: 0.75rem;
  z-index: 99999;
}
