/* .rpt-ctrl-wrapper {
  position: fixed;
  top: 70;
  left: 0;
  width: 100%;
} */

.rpt-ctrl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: auto;
}

.rpt-header-date-btn {
  text-align: right;
}

.bottom-picker {
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* .scrollable-report-list {
  margin-top: 100px;
} */

.rpt-hdr-m-header {
  text-align: center;
}
