/* mobile section */

.sales-container {
  overflow: hidden;
  height: 100%;
  margin-top: 10px;
}

.sales-content {
  margin-top: 20px;
  overflow: hidden;
}

.sales-grid {
  display: flex;
  justify-content: center;
}

.sales-panel-qty {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sales-chart {
  margin-bottom: 5px !important;
}

.submarginButton {
  background: linear-gradient(to bottom left, #4783c1, #2c4b69, #4783c1);
  background: -webkit-linear-gradient(
    to bottom left,
    #4783c1,
    #2c4b69,
    #4783c1
  );
  background: -moz-linear-gradient(to bottom left, #4783c1, #2c4b69, #4783c1);
  background: -o-linear-gradient(to bottom left, #4783c1, #2c4b69, #4783c1);
  box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);

  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}

.submarginButton:hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
}

.catmarginButton {
  background: linear-gradient(to bottom left, #b658ae, #70356b, #b658ae);
  background: -webkit-linear-gradient(
    to bottom left,
    #b658ae,
    #70356b,
    #b658ae
  );
  background: -moz-linear-gradient(to bottom left, #b658ae, #70356b, #b658ae);
  background: -o-linear-gradient(to bottom left, #b658ae, #70356b, #b658ae);
  box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(87, 76, 76, 0.29);

  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}

.catmarginButton:hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
}

.submargins {
  display: flex;
  justify-content: center;
}
/* 
.m-sales-date {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.m-sales-even {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #fff;
  font-weight: bold;
  text-align: center;
}

.m-sales-odd {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #fff;
  font-weight: bold;
  text-align: center;
}

.m-empty-sales {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.m-sales-store {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.m-sales {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.m-sales-green {
  color: #1a9c1a;
}

.m-sales-red {
  color: #ff0000;
}

.m-sales-margin {
  color: #546c84;
}



.d-sales-container {
  width: 100%;
  border: 1px solie #fff;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.d-sales {
  display: grid;
  grid-template-rows: 200px 1fr;
}

.d-sales-lower {
  overflow: auto;
}

.d-sales-row {
  cursor: pointer;
} */
