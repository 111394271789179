.margindate {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.marginrecords {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.marginstore {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.margin-table {
  width: 80%;
  margin: 0 auto;
}

.marginSubInfo {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.sub-item-margin-row {
  cursor: pointer;
}

.sub-item-margin-row:hover {
  background-color: rgba(71, 133, 194, 0.6) !important;
}
