.modal-review-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #272a3d;
  color: #fff;
  opacity: 0.5;
}

.modal-review-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-review {
  z-index: 100;
  background: #272a3d;
  color: #fff;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  max-width: 500px;
}

.modal-review-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: orange;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.modal-review-confirm-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.modal-review-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10;
}

.modal-review-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.modal-review-body {
  padding: 10px;
}

.modal-review-store {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.3rem;
}

.modal-review-user {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.modal-review-empid {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.modal-review-data {
  text-align: center;
}

.modal-review-total {
  text-align: right;
}

.modal-review-list {
  padding: 10px;
}

.pto-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.pto-date {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
}

.pto-d2 {
  /* text-align: right; */
}
