.query-builder {
  width: 70%;
  margin: 0 auto;
  border: 1px solid #cb2dd6;
  border-radius: 10px;
  /* box-shadow: 4px 4px 0px 4px rgba(71, 133, 194, 0.2),
    inset -4px -4px 0px 4px rgba(71, 133, 194, 0.2); */
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.qb-add {
  color: #4f26ed;
  cursor: pointer;
}

.qb-delete {
  margin-left: 20px;
  color: #ed3226;
  cursor: pointer;
}

.qb-spacer {
  margin-left: 10px;
}

.sqllabel {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

.sql {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  max-width: 100%;
  max-height: 300px;
  padding: 5px 10px 5px 10px;
  resize: none;
  line-height: 2;
  width: 100%;
  background-color: transparent;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
}

.previewStyle {
  background: linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  background: -webkit-linear-gradient(
    to bottom left,
    #4783c1,
    #3f6b97,
    #4783c1
  );
  background: -moz-linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  background: -o-linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}

.downloadStyle {
  background: linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  background: -webkit-linear-gradient(
    to bottom left,
    #2d7162,
    #4ca18e,
    #2d7162
  );
  background: -moz-linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  background: -o-linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}
