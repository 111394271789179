.jumbomini {
  padding: 1rem 0 0.5rem 1rem;
  margin-bottom: 2rem;
  background-color: transparent;
  border: 1px solid #4785c2;
  border-radius: 0.3rem;
  opacity: 0.8;
}

.hourly-d-body {
  display: grid;
  grid-template-columns: 30% 1fr;
}

.h-date {
  margin-top: 4px;
}

.HourlyChart {
  overflow: visible;
  height: 80%;
}

.hourly-row {
  cursor: pointer;
}

.hc-1-grid {
  opacity: 0.6;
}

.hc-1-bar {
  cursor: pointer;
}

.hc-1-bar:hover {
  opacity: 0.6;
}
