.modal-trans-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-trans-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-trans {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  max-width: 800px;
}

.modal-trans-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #1f1e2e;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.modal-trans-confirm-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.modal-trans-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10;
}

.modal-trans-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.modal-trans-body {
  padding: 10px;
  background-color: #272a3d;
  color: #fff;
}

.m-trans-reg {
  color: #fff;
}

.m-trans-table {
  width: 90%;
  margin: auto;
  color: #fff;
}

.m-trans-ttl {
  color: #fff;
  margin-top: 10px;
}

.m-trans-ttl-table {
  width: 90%;
  margin: auto;
  color: #fff;
}
