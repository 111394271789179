.employee-row {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #fff;
}

.employee-name {
  width: 20%;
  border-right: 1px solid #fff;
  margin-left: 10px;
  max-width: 20%;
  min-width: 20%;
}

.cell {
  border: 1px solid #fff;
  padding: 5px;
  /* margin-bottom: 5px; */
}

.pto-cell {
  text-align: center;
  color: red;
}

.day-cell {
  min-width: 10%;
  width: 10%;
  transition: background 500ms linear;
}

.employee-total {
  min-width: 10%;
}

.employee-shift {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  width: "100%";
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.shift-info {
  width: 100%;
  text-align: center;
}

.fade-shift-in {
  animation: fade-shift-In ease 1s;
  -webkit-animation: fade-shift-In ease 1s;
  -moz-animation: fade-shift-In ease 1s;
  -o-animation: fade-shift-In ease 1s;
  -ms-animation: fade-shift-In ease 1s;
}

.disabled-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-cell-content {
  transform: rotate(-45deg);
  color: red;
}

@keyframes fade-shift-In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-shift-In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-shift-In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fade-shift-In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fade-shift-In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
