.time-login-icon {
  width: 100%;
  margin-top: 100px;
  text-align: center;
  font-size: 8rem;
}

.forgotWrapper {
  padding: 10px;
  margin-top: 20px;
  width: 50%;
}

.email-message {
  text-align: center;
}

.keyboardfade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: keyboardfadeIn ease 2s;
  -moz-animation: keyboardfadeIn ease 2s;
  -o-animation: keyboardfadeIn ease 2s;
  -ms-animation: keyboardfadeIn ease 2s;
}

@media only screen and (max-width: 600px) {
  .forgotWrapper {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
  }
}

@keyframes keyboardfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes keyboardfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes keyboardfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes keyboardfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes keyboardfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
