.logo-offset {
  margin-right: 70px;
}
.menu-item {
  text-decoration: none;
  color: #000;
  margin-right: 10px;
  margin-top: 5px;
}

.menu-item:hover {
  color: #f15b2a;
}

.menu-dd {
  margin-top: -2px;
  color: white;
  margin-right: 8px;
  background-color: #222a41;
}

.menu-dd:hover {
  color: #f15b2a;
  text-decoration: underline;
}

.navbar-nav .dropdown-menu {
  background-color: #222a41;
  padding-left: 10px;
  float: none;
  position: static;
  display: flex;
  flex-direction: column;
}

.menu-item-sub {
  float: left;
  padding: 5px !important;
  display: block;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 !important;
  padding: 0 !important;
}

.dropdown-menu .show {
  background-color: #222a41;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transform: translate3d(0, 1px, 0) !important;
  -moz-transform: translate3d(0, 1px, 0) !important;
  -o-transform: translate3d(0, 1px, 0) !important;
  -ms-transform: translate3d(0, 1px, 0) !important;
  transform: translate3d(0, 1px, 0) !important;
}

hr.menu-hr {
  border: 1px solid white;
  width: 90%;
}
