.cc-wrapper {
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 23px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 23px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 23px -8px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  width: 90%;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.cc-header {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.cc-header-m {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}

.cc-header-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.cc-name {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.cc-score-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.cc-score-label {
  text-align: center;
}

.cc-score-m-label {
  text-align: center;
  margin-top: 10px;
}

.cc-score {
  border: 1px solid black;
  border-radius: 10px;
  font-size: 1rem;
  margin: 0;
  height: 30px;
  text-align: center;
  opacity: 0.8;
}
.cc-m-score {
  border: 1px solid black;
  border-radius: 10px;
  font-size: 1rem;
  margin: 0;
  height: 30px;
  text-align: center;
  opacity: 0.8;
  margin-left: 10px;
  padding: 3px;
}

.cc-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cc-footer-concern {
  text-align: center;
}

.cc-footer-purple {
  text-align: center;
}

.ccpurple {
  color: #b658ae;
  opacity: 0.8;
}

.ccgreen {
  color: #3eb8ad;
  opacity: 0.8;
}

.ccyellow {
  color: #ffd769;
  opacity: 0.8;
}

.ccorange {
  color: rgb(227, 122, 36);
  opacity: 0.8;
}

.ccred {
  color: #ed3226;
  opacity: 0.8;
}

.ccdefault {
  color: #1ca68a;
  opacity: 0.8;
}

@media only screen and (min-width: 576px) {
  .cc-wrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .cc-wrapper {
    width: 40%;
  }
}

@media only screen and (min-width: 992px) {
  .cc-wrapper {
    width: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .cc-wrapper {
    width: 20%;
  }
}
