.a40 {
  position: relative;
}

.lmask40 {
  /* position: absolute;
  height: 100%;
  width: 100%; */
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.4;
}
.lmask40.fixed40 {
  position: fixed;
}
.lmask40:before {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(210, 0, 229, 0.9);
  opacity: 0.9;
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 35px #e021e7;
  width: 50px;
  height: 50px;
  -moz-animation: spinPulse40 1s infinite ease-in-out;
  -webkit-animation: spinPulse40 1s infinite linear;
  animation: spinPulse40 1s infinite linear;
  margin: -25px 0 0 -25px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.lmask40:after {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(229, 0, 210, 0.9);
  opacity: 0.9;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 15px #e721e7;
  width: 30px;
  height: 30px;
  -moz-animation: spinoffPulse40 1s infinite linear;
  -webkit-animation: spinoffPulse40 1s infinite linear;
  animation: spinOffPulse40 1s infinite linear;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  left: 50%;
}

@-moz-keyframes spinPulse40 {
  0% {
    -moz-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #e721c6;
  }
  50% {
    -moz-transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    -moz-transform: rotate(-320deg);
    opacity: 0;
  }
}

@-moz-keyframes spinoffPulse40 {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinPulse40 {
  0% {
    -webkit-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #e721e7;
  }
  50% {
    -webkit-transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-320deg);
    opacity: 0;
  }
}

@keyframes spinPulse40 {
  0% {
    transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #e721d7;
  }
  50% {
    transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
  }
}

@-webkit-keyframes spinoffPulse40 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinoffPulse40 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
