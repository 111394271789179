.homepage-base {
  overflow: auto;
  height: 100%;
}

.dashboard-content {
  margin-top: 10px;
  margin-bottom: 50px;
}

.welcom {
  color: #fff;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 5 0 5 0;
}

.chartsHeader {
  text-align: center;
}

.emptyCharts {
  padding: 20px;
  text-align: center;
}

.chartsHeaderTitle {
  font-size: 1.4rem;
  font-weight: bold;
}

.chartsAddButton {
  font-size: 2rem;
  cursor: pointer;
  margin-left: 10px;
}

.banner-logos {
  background: transparent;
  margin-top: 8px;
}

.tlz-dropdown {
  background: rgba(39, 42, 61, 1) !important;
  color: #fff !important;
  margin-top: 0 !important;
  -webkit-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  transition: all 3s ease-in;
}

.tlz-dropdown:hover {
  background: rgba(62, 184, 173, 0.5) !important;
  color: #005 !important;
  font-weight: bold;
  opacity: 0.8;
  -webkit-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  transition: all 3s ease-in;
}

.dropdown-active {
  background-color: rgba(29, 140, 248, 0.5) !important;
  color: black !important;
}

.dropdown-inactive {
  background: rgba(39, 42, 61, 1) !important;
  color: #fff !important;
}

.pivotButton {
  margin-bottom: 10px;
}
