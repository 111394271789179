.cat-buttons {
  display: flex;
  justify-content: space-between;
}

.cat-filters {
  display: flex;
  justify-content: space-between;
}

.cat-item-margin-row {
  cursor: pointer;
}

.cat-item-margin-row:hover {
  background-color: rgba(71, 133, 194, 0.6) !important;
}
