@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://cdn.syncfusion.com/ej2/material.css");
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules//@syncfusion/ej2-react-pivotview/styles/material.css";

.pivot-controls {
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  z-index: 9999999;
}

.testStyle {
  background: linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  background: -webkit-linear-gradient(
    to bottom left,
    #4783c1,
    #3f6b97,
    #4783c1
  );
  background: -moz-linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  background: -o-linear-gradient(to bottom left, #4783c1, #3f6b97, #4783c1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}

.salesStyle {
  background: linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  background: -webkit-linear-gradient(
    to bottom left,
    #2d7162,
    #4ca18e,
    #2d7162
  );
  background: -moz-linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  background: -o-linear-gradient(to bottom left, #2d7162, #4ca18e, #2d7162);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.29);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  max-width: 175px;
  max-height: 45px;
  transition: all 0.3s ease-in-out;
}

.testStyle:hover {
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}

.salesStyle:hover {
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}

.pivot-header-controls {
  border: 1px solid #fff;
  text-align: right;
  padding: 5px;
}

.pivot-header-tool {
  padding: 10px;
  cursor: pointer;
}

.pivot-header-mode {
  float: left;
  font-size: 1.3rem;
  font-weight: bold;
  color: #2d7162;
}

.empty-pivot {
  margin-top: 5px;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
}

.db-utils {
  background: transparent;
  text-align: center;
  margin-top: 3px;
  cursor: pointer;
}

.pivot-mode {
  font-size: 1.2rem;
  font-weight: bold;
  color: limegreen;
  margin-left: 5px;
  display: flex;
  align-items: end;
}
