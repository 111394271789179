.d-reports-list-header {
  font-size: 1.3rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.d-reports-list-table {
  margin-top: 10px;
}

.d-report-item {
  border: 1px solid #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.d-report-item:hover {
  background-color: #eee;
}

.d-report-item:active {
  background-color: limegreen;
}

.d-report-icon {
  font-size: 1.3rem;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
}

.d-report-name {
  font-size: 1.3rem;
  font-weight: bold;
}

.d-report-description {
  margin-left: 20px;
  margin-top: 5px;
}

.report-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.report-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.report-contents {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  width: 90%;
}

.report-contents-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #1f1e2e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.report-contents-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.report-contents-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10px;
}

.report-confirm-delete-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.report-confirm-delete-body {
  padding: 10px;
}

.print-div {
  position: absolute;
  right: 0px;
  margin-right: 40px;
  margin-top: 40px;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
}
