.form4-history-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.odd-form4 {
  background-color: #403e3e;
  color: #fff;
}

.form4-header-item {
  font-weight: bold;
  font-size: 1.2rem;
}

.even-form4 {
  background-color: rgb(32, 39, 47);
  color: #fff;
}
