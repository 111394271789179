.tableFixHead {
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 20px;
  margin-top: 20px;
  transition: 0.5s ease;
}
.tableFixHead th {
  position: sticky;
  top: 0;
  background-color: #272a3d;
}

.hideMetrics {
  max-height: 0px;
  overflow-y: hidden;
  transition: 0.5s ease;
}

@media only screen and (max-width: 1024px) {
  .freshop-metrics {
    display: none;
  }
}
