.users-list {
  overflow: auto;
  width: 100%;
}

.user-line {
  line-height: 16px;
  border-bottom: 1px solid black;
  font-size: 1.2rem;
  font-weight: bold;
}

.user-icon {
  text-align: center;
}

.user-delete-icon {
  text-align: center;
}

.user-search-box {
  float: left;
  width: 85% !important;
}

.user-search-button {
  float: right;
  padding: 6px 10px;
  margin-right: 16px;
  background: transparent;
  font-size: 17px;
  border: none;
  cursor: pointer;
  width: 10%;
  height: calc(1.5em + 0.75rem + 2px);
  background-color: #eee;
}

.deleteerror {
  clear: both;
  background-color: red;
  color: white;
  display: inline-block;
  padding: 5px;
  margin: auto;
  text-align: center;
  width: 80%;
}

.deleteok {
  clear: both;
  background-color: green;
  color: white;
  display: inline-block;
  padding: 5px;
  margin: auto;
  text-align: center;
  width: 80%;
}

.popup-content {
  margin: auto;
  background: #1f1e2e;
  color: #fff;
  padding: 5px;
  border: 1px solid #d7d7d7;
  transition: all 0.5s ease;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -moz-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -o-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

[role="tooltip"].popup-content {
  width: 300px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: all 0.5s ease;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
