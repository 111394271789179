.password-title-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.password-title {
  text-align: left;
  font-weight: bold;
}

.password-warning {
  color: red;
  text-align: right;
}

.password-instructions {
  text-align: center;
  font-size: 0.8em;
}

.password-bar-container-hidden {
  display: none;
}

.password-bar-container {
  height: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 10px;
  margin-top: 10px;
}

.password-bar {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
}

.empty-password-bar {
  background-color: #eee;
}

.bad-password {
  background-color: darkred;
}

.ok-password {
  background-color: orangered;
}

.decent-password {
  background-color: orange;
}

.better-password {
  background-color: yellowgreen;
}

.good-password {
  background-color: green;
}

.zxcvb-score {
}
