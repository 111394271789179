.rec-row {
  transition: all 0.3s ease;
  cursor: pointer;
}

.rec-row:hover {
  background-color: #3482c2;
  color: #fff;
}

.rec-table {
  color: #fff;
}

.rec-overlay {
  position: absolute;
  background-color: #1e1e2f;
  left: 100%;
  right: 0;
  width: 0;
  overflow: hidden;
  height: 100%;
  transition: 0.5s ease;
}

.slidin {
  width: 100%;
  left: 0;
  top: 0;
  opacity: 1;
}

.slidout {
  width: 0;
  left: 100%;
  top: 1000px;
  opacity: 0;
}

.rec-table-center {
  text-align: center;
}

.rec-table-right {
  text-align: right;
}
