.po-header {
  color: #fff;
  background-image: url("../../img/Fuel-Rewards-Web-Account.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 75px;
}
.po-header-banner {
  background-color: #002767;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 7px;
}

.po-header-banner-bold {
  display: inline;
  font-weight: bold;
  font-size: 1.4rem;
  vertical-align: middle;
}

.po-header-banner-text {
  display: inline;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
}

.po-instructions {
  width: 80%;
  margin: auto;
  margin-top: 5px;
  font-style: italic;
  font-size: 0.8rem;
  text-align: center;
}

.po-data-body {
  margin-top: 20px;
}

.po-current-points-text {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.po-current-points-points {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.po-fuel-savings {
  padding: 5px;
  margin: 10px;
  background-color: #002767;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 8px;
  text-align: center;
}

.po-fuel-disclaimer {
  font-size: 0.8rem;
  text-align: center;
}

.po-point-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.po-point-history-current {
  padding: 10px;
}

.po-point-history-current-circle {
  position: relative;
  border-radius: 50%;
  border: 1px solid #002767;
  width: 100%;
  height: auto;
  padding-top: 100%;
  cursor: pointer;
}

.po-point-history-current-circle-month {
  text-align: center;
  position: absolute;
  top: 30;
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
}

.po-point-history-current-circle-points {
  text-align: center;
  position: absolute;
  top: 60;
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
}

.po-point-history-current-circle-view-points {
  text-align: center;
  position: absolute;
  top: 100;
  width: 100%;
}

.po-point-history-previous {
  padding: 10px;
}

.po-point-history-previous-circle {
  position: relative;
  border-radius: 50%;
  border: 1px solid #002767;
  width: 100%;
  height: auto;
  padding-top: 100%;
  cursor: pointer;
}

.po-point-history-previous-circle-month {
  text-align: center;
  position: absolute;
  top: 30;
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
}

.po-point-history-previous-circle-points {
  text-align: center;
  position: absolute;
  top: 60;
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
}

.po-point-history-previous-circle-view-points {
  text-align: center;
  position: absolute;
  top: 100;
  width: 100%;
}

.po-point-history-current-expiration {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.po-point-history-current-exp-month {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.po-point-history-current-exp-date {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.po-point-history-previous-expiration {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.po-point-history-previous-exp-month {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.po-point-history-previous-exp-date {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

/* desktop modifications */
.po-point-history-d {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  margin: auto;
}

.po-point-history-previous-circle-month-d,
.po-point-history-current-circle-month-d {
  text-align: center;
  position: absolute;
  top: 40;
  width: 100%;
  font-weight: bold;
  font-size: 3rem;
}

.po-point-history-previous-circle-points-d,
.po-point-history-current-circle-points-d {
  text-align: center;
  position: absolute;
  top: 105;
  width: 100%;
  font-weight: bold;
  font-size: 3rem;
}

.po-point-history-previous-circle-view-points-d,
.po-point-history-current-circle-view-points-d {
  text-align: center;
  position: absolute;
  top: 190;
  width: 100%;
}

.po-point-history-previous-expiration-d,
.po-point-history-current-expiration-d {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-top: 10px;
}

.po-header-d {
  color: #fff;
  background-image: url("../../img/Fuel-Rewards-Web-Account.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  background-position: center center;
}

.po-current-points-points-d {
  font-weight: bold;
  font-size: 8rem;
  text-align: center;
  line-height: 5rem;
  margin-bottom: 40px;
}

.po-header-container {
  margin: auto;
}
