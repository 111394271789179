.cc-main-body {
  position: fixed;
  top: 50px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
  padding: 10px;
}

.cc-form-item {
  margin-top: 10px;
}
