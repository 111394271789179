.modal-punch-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-punch-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-punch {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  max-width: 500px;
}

.modal-punch-header {
  display: flex;
  border-bottom: 1px solid black;
  background-color: #1f1e2e;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.modal-punch-confirm-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.modal-punch-close-box {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 10;
}

.modal-punch-close-button {
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  padding-bottom: 3px;
}

.modal-punch-body {
  padding: 10px;
  background-color: #272a3d;
  color: #fff;
}

.modal-punch-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
